import { Component, OnInit } from '@angular/core';
// import { IrisksortedField, IDocumentCollectionOutputModel } from "../../../../../../common/contracts/documentCollection";
import { ActivatedRoute, Router } from "@angular/router";
import { ISortOrderValue } from "../../../../../../common/contracts/common";
import { PersistentStorageService } from "../../../../../service/PersistentStorageService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { CurrentUserService } from '../../../../../service/currentUser/CurrentUserService';
import { RiskService } from 'service/RiskService';
import { Risk } from '../../../../../../common/Risk';
import { riskTypeMap, riskTypeOptions } from 'model/RiskType';
import { logger } from 'service/util/Logger';
import { ReportService } from 'service/ReportService';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ModalService } from 'service/ModalService';
import { DocumentCollectionsService } from 'service/admin/DocumentCollectionsService';
import { Session } from 'service/util/Session';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
@Component({
  selector: 'app-risks-component',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss']
})
export class RiskComponent implements OnInit {

  public riskTypeOptions = [{ id: '', text: '' }].concat(riskTypeOptions);
  public risks: any = [];
  public totalItems = 0;
  public currentPage = 1;
  public pageSize = this.pss.pageSize;
  public sortBy = 'summary';
  public sortOrder: ISortOrderValue = 'asc';
  public isAdmin: boolean = false;
  public searchFilter: string = '';
  public locationFilter: string = '';
  public riskLevelFilter: string = "";
  public riskStatusFilter: string = "";
  public riskTypeId: string = ""
  public riskSelectOptions = [{ id: '', text: '' }].concat(Risk.RiskOptions);
  public riskStatus = [{ id: '', text: '' }, { id: "1", text: "Open" }, { id: "2", text: "Closed" }];

  private riskDataUpdateSubject = new BehaviorSubject<boolean>(false);

  constructor(private groupsService: RiskService,
    private router: Router,
    private route: ActivatedRoute,
    private pss: PersistentStorageService,
    private errorHandler: ErrorHandlerService,
    private reportService: ReportService,
    private userService: CurrentUserService,
    private modalService: ModalService,
    private collectionsService: DocumentCollectionsService,
    private session: Session,) { }

  ngOnInit() {
    this.isAdmin = this.userService.isAdministrator.getValue();
    this.handlePageChange();

    this.riskDataUpdateSubject.pipe(
      filter(val => !!val),
      debounceTime(200)
    ).subscribe(() => {
      this.fetchRiskData();
    })
  }

  public handleRiskClick(groupId: number | string) {
    this.router.navigate([`${groupId}`], { relativeTo: this.route });
  }

  public handlePageChange() {
    logger.silly(`handlePageChange`);
    this.riskDataUpdateSubject.next(true);
  }

  public fetchRiskData() {
    logger.silly(`fetchRiskData`);
    this.risks = [];
    let search = this.searchFilter.length > 0 ? this.searchFilter : '';
    this.groupsService.getRisks(
      this.pageSize * (this.currentPage - 1),
      this.pageSize,
      this.sortBy,
      this.sortOrder,
      search,
      this.locationFilter,
      this.riskLevelFilter,
      this.riskStatusFilter === 'Close' ? 'ALARP' : this.riskStatusFilter,
      this.riskTypeId
    ).subscribe((data) => {
      data.items.forEach(item => {
        this.risks.push({
          ...item,
          riskStatus: item.riskStatus === 'ALARP' ? 'Closed' : item.riskStatus,
          riskType: riskTypeMap[item.riskTypeId]
        })

      });
      this.totalItems = data.totalCount;
    }, (err) => this.errorHandler.handleHttpError(err));
  }

  public handleSortChange(sortField) {
    logger.silly(`HandleSortChange`);
    if (sortField === this.sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = sortField;
      this.sortOrder = 'asc';
    }
    this.handlePageChange();
  }

  public performSearch() {
    logger.silly(`performSearch`);
    this.searchFilter = this.searchFilter.trim();
    this.currentPage = 1;
    this.handlePageChange();
  }

  public clearSearch() {
    logger.silly(`clearSearch`);
    this.searchFilter = '';
    this.currentPage = 1;
    this.handlePageChange();
  }

  public handleChangeRiskLevel = (event) => {
    logger.silly(`handleChangeRiskLevel`);
    let value = event.data[0].text;
    this.riskLevelFilter = value;
    this.currentPage = 1;
    this.handlePageChange();

  }

  public handleChangeRiskStatus = (event) => {
    logger.silly(`handleChangeRiskStatus`);

    let value = event.data[0].text;
    this.riskStatusFilter = value;
    this.currentPage = 1;
    this.handlePageChange();

  }

  public handleChangeRiskTypeFilter(id) {
    logger.silly(`handleChangeRiskTypeFilter`);

    if (!id) {
      this.riskTypeId = "";
    } else {

      this.riskTypeId = id;
    }
    this.currentPage = 1;
    this.handlePageChange();


  }

  public handleLocationFilterChange(selected: { id: string }[]) {
    logger.silly(`handleLocationFilterChange`);
    if (!selected || selected.length === 0) {
      logger.silly("Updated QueryParam: locationId: [blank string]");
      this.locationFilter = '';
    } else {
      const oldValue = this.locationFilter;
      const newValue = selected.map(item => item.id).filter(id => id && id.length).join(",");
      logger.silly(`Updated QueryParam: locationId: Old[${oldValue}] New[${newValue}]`);
      this.locationFilter = newValue;
    }
    this.currentPage = 1;

    this.handlePageChange();
  }

  public handleCsvExport() {
    let search = this.searchFilter.length > 0 ? this.searchFilter : ''
    this.reportService.downloadRISKcsvReport(this.pageSize * (this.currentPage - 1),
      this.pageSize,
      this.sortBy,
      this.sortOrder,
      search,
      this.locationFilter,
      this.riskLevelFilter,
      this.riskStatusFilter,
      this.riskTypeId).subscribe((data) => {
        saveAs(data, `risk-report-${moment().format('YYYY-MMM-DD')}.csv`);
      }, err => this.errorHandler.handleHttpError(err));
  }

  public handleImportClick = () => {
    this.modalService.upload({ accept: ".csv" }).subscribe(importFile => {
      if (!importFile) return;
      console.log("import file", importFile)
      this.session.lockInputRx(
        this.groupsService.importRisks(importFile.id)
      ).subscribe(
        resp => {
          this.collectionsService.clearCache();
          this.handlePageChange();

          this.modalService.alert({
            title: "Import Complete",
            message: resp.message
          });
        },
        err => {
          this.errorHandler.handleHttpError(err);
        }
      );
    });
  }
}



