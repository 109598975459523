var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import "moment-timezone";
import { FormService } from "../../../../service/FormService";
import { Session } from "../../../../service/util/Session";
import { CurrentUserService } from "../../../../service/currentUser/CurrentUserService";
import { PersistentStorageService } from "../../../../service/PersistentStorageService";
import { TruncatePipe } from "../../../../pipes/truncate.pipe";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { UserSelectComponent } from "../../../shared/userSelect.component";
import { ReportService } from "../../../../service/ReportService";
import { CategoryService } from "../../../../service/CategoryService";
import { combineLatest } from "rxjs";
import { logger } from "service/util/Logger";
import { environment } from "../../../../environments/environment";
import { DashboardFilterComponent } from "../dashboard-filter/dashboard-filter.component";
import { map } from "rxjs/operators";
import { ArbitraryValueCache } from "service/util/ArbitraryValueCache";
var DashboardRow = /** @class */ (function () {
    function DashboardRow() {
    }
    return DashboardRow;
}());
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(formService, categoryService, session, router, currentUserService, pss, errorHandler, reportService, truncatePipe, arbitraryValueCache) {
        var _this = this;
        this.formService = formService;
        this.categoryService = categoryService;
        this.session = session;
        this.router = router;
        this.currentUserService = currentUserService;
        this.pss = pss;
        this.errorHandler = errorHandler;
        this.reportService = reportService;
        this.truncatePipe = truncatePipe;
        this.arbitraryValueCache = arbitraryValueCache;
        this.className = "DashboardComponent";
        this.dashboardRecords = [];
        this.alertCount = 0;
        this.warningCount = 0;
        this.taskCount = 0;
        this.notificationCount = 0;
        this.currentPage = 1;
        this.pageSize = this.pss.pageSize;
        this.showAllOptions = [
            { value: 'all', text: 'All' },
            { value: 'complete', text: 'Complete' },
            { value: 'active', text: 'Active' },
        ];
        this.searchFilter = '';
        this.originatorFilter = '';
        this.dateFilterMap = {
            none: 'Filter By Date',
            createdAt: 'Created At',
            dueAt: 'Due Date',
        };
        this.selectedDateFilter = 'none';
        this.selectedCategoryFilter = null;
        this.filterStartDate = '';
        this.filterEndDate = '';
        this.formCategories = this.categoryService.getCategories().pipe(map(function (categories) { return categories.filter(function (cat) { return cat.name !== 'DRM-Task'; }); }));
        // Update the page number if it is updated in the filter
        this.arbitraryValueCache.onChange(DashboardFilterComponent.CACHE_KEY).subscribe(function (filter) {
            if (filter === null)
                return;
            if (filter === undefined)
                return;
            if (typeof filter !== 'object')
                return;
            var filterObj = filter || {};
            if ('page' in filterObj) {
                var pageNum = Number(filterObj['page']);
                if (Number.isNaN(pageNum))
                    return;
                if (_this.currentPage !== pageNum) {
                    _this.currentPage = pageNum;
                }
            }
        });
    }
    DashboardComponent.prototype.toQueryParams = function (filter) {
        var limit = filter.pageSize ? Number(filter.pageSize) : 10;
        return {
            limit: limit,
            skip: filter.page ? (Number(filter.page) - 1) * limit : 0,
            sortBy: filter.sortBy,
            order: filter.order,
            assignedUserId: filter.assignedUserId ? Number(filter.assignedUserId) : undefined,
            showAll: filter.showAll,
            //dueFilter: filter.dueFilter,
            // @ts-ignore
            createdAtStart: filter.createdAtStart,
            // @ts-ignore
            createdAtEnd: filter.createdAtEnd,
            // @ts-ignore
            dueAtStart: filter.dueAtStart,
            // @ts-ignore
            dueAtEnd: filter.dueAtEnd,
            search: filter.search,
            category: filter.category ? Number(filter.category) : undefined,
            reportFormType: filter.reportFormType ? Number(filter.reportFormType) : undefined,
            originatorName: filter.originatorName,
            locationId: filter.locationId,
            groupId: filter.groupId,
            dueFilter: filter.dueFilter
        };
    };
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        logger.silly("Dashboard Component Init");
        this.isAdmin = this.currentUserService.isAdministrator.getValue();
        this.isAdminOrManager = this.isAdmin || this.currentUserService.userData.role === 'manager';
        this.dashboardFilter.filterChange.subscribe({
            next: function (filter) {
                var queryParams = _this.toQueryParams(filter);
                if (queryParams.category === null || queryParams.category === undefined) {
                    queryParams.excludeCategories = '4';
                }
                _this.loadForms(queryParams);
            }
        });
    };
    DashboardComponent.prototype.handlePageChange = function () {
        this.dashboardFilter.filter.page = String(this.currentPage);
        this.dashboardFilter.publishFilter();
    };
    DashboardComponent.prototype.handlePageSizeChange = function (pageSize) {
        this.dashboardFilter.filter.pageSize = String(pageSize);
        this.dashboardFilter.publishFilter();
    };
    DashboardComponent.prototype.loadForms = function (query) {
        var _this = this;
        var signature = this.className + ".LoadForms: ";
        logger.silly(signature + "Loading Forms");
        var formsRequest = this.formService.getForms(query);
        var queryWithoutStatus = __assign({}, query);
        delete queryWithoutStatus.dueFilter;
        var formsWithoutStatusRequest = query.dueFilter ? this.formService.getForms(queryWithoutStatus) : formsRequest;
        this.session.lockInputRx(combineLatest([formsRequest, formsWithoutStatusRequest]))
            .subscribe(function (result) {
            var data = result[0], stats = result[1];
            logger.silly(signature + "Recieved form Data");
            var dashboardRecords = [];
            _this.totalForms = data.totalCount;
            data.items.forEach(function (formItem) {
                var formType = formItem["category"]["name"];
                if (formType === 'Report')
                    formType = 'Feedback';
                var createdDate = new Date(formItem["createdAt"]);
                var dueDate = formItem["dueAt"] ? new Date(formItem["dueAt"]) : null;
                var title = "" + _this.truncatePipe.transform(formItem.summary || 'unknown');
                dashboardRecords.push({
                    title: title,
                    formType: formType,
                    createdDate: createdDate,
                    dueDate: dueDate,
                    type: formType,
                    isAlert: !!formItem.isOverdue,
                    isWarning: !!formItem.isAlertOverdue,
                    isFinalized: !!formItem.archivedAt,
                    id: formItem["id"],
                    stage: formItem["stage"],
                    archivedAtDateString: formItem["archivedAt"] ? moment(formItem["archivedAt"]).tz(environment.timeZone).format("DD-MM-YY") : '',
                    createdAtDateString: moment(createdDate).tz(environment.timeZone).format("DD-MM-YY"),
                    dueDateString: dueDate ? moment(dueDate).tz(environment.timeZone).format("DD-MM-YY") : '',
                    assignedUser: formItem.assignedUser ? formItem.assignedUser.firstName + " " + formItem.assignedUser.lastName + " " : null,
                    assignedGroup: formItem.userGroup ? "" + formItem.userGroup.groupName : null,
                    location: formItem.formLocation ? formItem.formLocation.name : 'All Locations'
                });
            });
            _this.formService.getForms({
                skip: 0, limit: 10, sortBy: 'dueAt', order: 'asc', showAll: 'active', category: 4,
                groupId: query.groupId,
                assignedUserId: query.assignedUserId
            }).subscribe(function (data) {
                _this.notificationCount = data.remainingTasks;
            });
            _this.dashboardRecords = dashboardRecords;
            _this.taskCount = stats.remainingTasks;
            _this.warningCount = stats.alertTasks;
            _this.alertCount = stats.overdueTasks;
        }, function (err) {
            console.error('Error while getting forms in dashboard', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    DashboardComponent.prototype.progressForm = function (row) {
        if (row.formType === 'Report' || row.formType === 'Feedback') {
            this.router.navigateByUrl("/report/" + row.id);
        }
        else if (row.formType === 'Follow-Up') {
            this.router.navigateByUrl("/followUp/" + row.id);
        }
        else if (row.formType === 'Audit') {
            this.router.navigateByUrl("/audit/" + row.id);
        }
        else if (row.formType === 'Risk Task') {
            this.router.navigateByUrl("/risk-assessment-task/" + row.id);
        }
        else {
            console.error("Unknown Form Type " + row.formType + ". Unable to navigate");
        }
    };
    DashboardComponent.prototype.toggleTopCardFilter = function (filter) {
        if (this.dashboardFilter.filter.dueFilter === filter) {
            delete this.dashboardFilter.filter.dueFilter;
        }
        else {
            this.dashboardFilter.filter.dueFilter = filter;
        }
        this.dashboardFilter.filter.showAll = 'active';
        this.dashboardFilter.filter.page = '1';
        this.dashboardFilter.publishFilter();
    };
    DashboardComponent.prototype.handleCsvExport = function () {
        var _this = this;
        var queryParams = this.toQueryParams(this.dashboardFilter.filter);
        this.reportService.downloadDashboardCsvReport(queryParams).subscribe(function (data) {
            saveAs(data, "dashboard-report-" + moment().format('YYYY-MMM-DD') + ".csv");
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    DashboardComponent.prototype.handleSortChange = function (sortField) {
        if (sortField !== this.dashboardFilter.filter.sortBy) {
            this.dashboardFilter.filter.sortBy = sortField;
            this.dashboardFilter.filter.order = 'asc';
        }
        else {
            this.dashboardFilter.filter.order = this.dashboardFilter.filter.order === 'asc' ? 'desc' : 'asc';
        }
        this.dashboardFilter.publishFilter();
    };
    return DashboardComponent;
}());
export { DashboardComponent };
