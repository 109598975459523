import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import "moment-timezone";
import { FormService } from "../../../../service/FormService";
import { Session } from "../../../../service/util/Session";
import { IFormsQueryParams } from "../../../../../common/contracts/form";
import { CurrentUserService } from "../../../../service/currentUser/CurrentUserService";
import { PersistentStorageService } from "../../../../service/PersistentStorageService";
import { TruncatePipe } from "../../../../pipes/truncate.pipe";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { UserSelectComponent } from "../../../shared/userSelect.component";
import { ReportService } from "../../../../service/ReportService";
import { CategoryService } from "../../../../service/CategoryService";
import { ICategoryOutputModel } from "../../../../../common/contracts/category";
import { combineLatest, Observable } from "rxjs";
import { logger } from "service/util/Logger";
import { environment } from "../../../../environments/environment";
import { DashboardFilter, DashboardFilterComponent } from "../dashboard-filter/dashboard-filter.component";
import { map } from "rxjs/operators";
import { ArbitraryValueCache } from "service/util/ArbitraryValueCache";

class DashboardRow {
  id: number;

  type: string;
  title: string;
  location: string | null;
  formType: string;

  createdDate: Date;
  dueDate: Date | null;

  createdAtDateString: string;
  dueDateString: string;
  archivedAtDateString: string;

  isAlert: boolean;
  isWarning: boolean;
  isFinalized: boolean;

  assignedUser: string | null;
  assignedGroup: string | null;

  stage: number;
}

@Component({
  selector: 'app-admin',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public readonly className = "DashboardComponent";

  @ViewChild('userSelect') userSelectorRef: UserSelectComponent;
  @ViewChild('dashboardFilter') dashboardFilter: DashboardFilterComponent;

  public dashboardRecords: DashboardRow[] = [];

  public alertCount: number = 0;
  public warningCount: number = 0;
  public taskCount: number = 0;
  public notificationCount: number = 0;

  public currentPage = 1;
  public isAdmin: boolean;
  public isAdminOrManager: boolean;
  public pageSize = this.pss.pageSize;
  public totalForms: number;
  public currentUserId: number | null;
  public defaultAssignedUserId: string;
  public defaultAssignedGroupId: string;
  public defaultAssignedLocationId: string;
  public showAllOptions = [
    { value: 'all', text: 'All' },
    { value: 'complete', text: 'Complete' },
    { value: 'active', text: 'Active' },
  ];

  public searchFilter: string = '';
  public originatorFilter: string = '';

  public dateFilterMap = {
    none: 'Filter By Date',
    createdAt: 'Created At',
    dueAt: 'Due Date',
  };

  public selectedDateFilter: string = 'none';

  public selectedCategoryFilter: ICategoryOutputModel | null = null;

  public filterStartDate: string = '';
  public filterEndDate: string = '';
  public formCategories: Observable<ICategoryOutputModel[]>;

  constructor(
    public formService: FormService,
    private categoryService: CategoryService,
    public session: Session,
    public router: Router,
    public currentUserService: CurrentUserService,
    private pss: PersistentStorageService,
    private errorHandler: ErrorHandlerService,
    private reportService: ReportService,
    private truncatePipe: TruncatePipe,
    private arbitraryValueCache: ArbitraryValueCache
  ) {
    this.formCategories = this.categoryService.getCategories().pipe(map(categories => categories.filter(cat => cat.name !== 'DRM-Task')));

    // Update the page number if it is updated in the filter
    this.arbitraryValueCache.onChange(DashboardFilterComponent.CACHE_KEY).subscribe(filter => {
      if( filter === null ) return;
      if( filter === undefined ) return;
      if( typeof filter !== 'object' ) return;
      const filterObj = filter || {};

      if( 'page' in filterObj ) {
        const pageNum = Number(filterObj['page']);
        if( Number.isNaN(pageNum) ) return;
        if( this.currentPage !== pageNum ) {
          this.currentPage = pageNum;
        }
      }
  });
  }

  private toQueryParams(filter: DashboardFilter): IFormsQueryParams {
    const limit = filter.pageSize ? Number(filter.pageSize) : 10;

    return {
      limit,
      skip: filter.page ? (Number(filter.page) - 1) * limit : 0,
      sortBy: filter.sortBy,
      order: filter.order,
      assignedUserId: filter.assignedUserId ? Number(filter.assignedUserId) : undefined,
      showAll: filter.showAll,
      //dueFilter: filter.dueFilter,
      // @ts-ignore
      createdAtStart: filter.createdAtStart,
      // @ts-ignore
      createdAtEnd: filter.createdAtEnd,
      // @ts-ignore
      dueAtStart: filter.dueAtStart,
      // @ts-ignore
      dueAtEnd: filter.dueAtEnd,
      search: filter.search,
      category: filter.category ? Number(filter.category) : undefined,
      reportFormType: filter.reportFormType ? Number(filter.reportFormType) : undefined,
      originatorName: filter.originatorName,

      locationId: filter.locationId,
      groupId: filter.groupId,
      dueFilter: filter.dueFilter
    }
  }

  ngOnInit() {
    logger.silly("Dashboard Component Init");
    this.isAdmin = this.currentUserService.isAdministrator.getValue();
    this.isAdminOrManager = this.isAdmin || this.currentUserService.userData!.role === 'manager';

    this.dashboardFilter.filterChange.subscribe({
      next: filter => {
        const queryParams = this.toQueryParams(filter);

        if( queryParams.category === null || queryParams.category === undefined ) {
          queryParams.excludeCategories = '4';
        }

        this.loadForms(queryParams);
      }
    });
  }

  public handlePageChange() {
    this.dashboardFilter.filter.page = String(this.currentPage);
    this.dashboardFilter.publishFilter();
  }

  public handlePageSizeChange(pageSize: number) {
    this.dashboardFilter.filter.pageSize = String(pageSize);
    this.dashboardFilter.publishFilter();
  }

  public loadForms(query: IFormsQueryParams) {
    const signature = this.className + ".LoadForms: ";
    logger.silly(signature + "Loading Forms");

    const formsRequest = this.formService.getForms(query);
    const queryWithoutStatus = { ...query };
    delete queryWithoutStatus.dueFilter;
    const formsWithoutStatusRequest = query.dueFilter ? this.formService.getForms(queryWithoutStatus) : formsRequest;

    this.session.lockInputRx(
      combineLatest([ formsRequest, formsWithoutStatusRequest ])
  )
      .subscribe((result) => {
        const [data, stats] = result;
        logger.silly(signature + "Recieved form Data");

        let dashboardRecords: DashboardRow[] = [];

        this.totalForms = data.totalCount;

        data.items.forEach(formItem => {
          let formType: string = formItem["category"]["name"];
          if (formType === 'Report') formType = 'Feedback';
          const createdDate: Date = new Date(formItem["createdAt"]);
          const dueDate: Date | null = formItem["dueAt"] ? new Date(formItem["dueAt"] as string) : null;
          const title = `${this.truncatePipe.transform(formItem.summary || 'unknown')}`;

          dashboardRecords.push({
            title,
            formType,
            createdDate,
            dueDate,
            type: formType,
            isAlert: !!formItem.isOverdue,
            isWarning: !!formItem.isAlertOverdue,
            isFinalized: !!formItem.archivedAt,
            id: formItem["id"],
            stage: formItem["stage"],
            archivedAtDateString: formItem["archivedAt"] ? moment(formItem["archivedAt"]).tz(environment.timeZone).format("DD-MM-YY") : '',
            createdAtDateString: moment(createdDate).tz(environment.timeZone).format("DD-MM-YY"),
            dueDateString: dueDate ? moment(dueDate).tz(environment.timeZone).format("DD-MM-YY") : '',
            assignedUser: formItem.assignedUser ? `${formItem.assignedUser.firstName} ${formItem.assignedUser.lastName} ` : null,
            assignedGroup: formItem.userGroup ? `${formItem.userGroup.groupName}` : null,
            location: formItem.formLocation ? formItem.formLocation.name : 'All Locations'
          });
        });

        this.formService.getForms({
          skip: 0, limit: 10, sortBy: 'dueAt', order: 'asc', showAll: 'active', category: 4,
          groupId: query.groupId,
          assignedUserId: query.assignedUserId
        }).subscribe(data => {
          this.notificationCount = data.remainingTasks
        });

        this.dashboardRecords = dashboardRecords;
        this.taskCount = stats.remainingTasks;
        this.warningCount = stats.alertTasks;
        this.alertCount = stats.overdueTasks;
      },
        err => {
          console.error('Error while getting forms in dashboard', err);
          this.errorHandler.handleHttpError(err);
        });

  }

  public progressForm(row: DashboardRow) {
    if (row.formType === 'Report' || row.formType === 'Feedback') {
      this.router.navigateByUrl(`/report/${row.id}`);
    } else if (row.formType === 'Follow-Up') {
      this.router.navigateByUrl(`/followUp/${row.id}`);
    } else if (row.formType === 'Audit') {
      this.router.navigateByUrl(`/audit/${row.id}`);
    } else if (row.formType === 'Risk Task') {
      this.router.navigateByUrl(`/risk-assessment-task/${row.id}`);
    } else {
      console.error(`Unknown Form Type ${row.formType}. Unable to navigate`);
    }
  }

  public toggleTopCardFilter(filter: "overdue" | "alert" | "remaining") {
    if (this.dashboardFilter.filter.dueFilter === filter) {
      delete this.dashboardFilter.filter.dueFilter;
    } else {
      this.dashboardFilter.filter.dueFilter = filter;
    }
    this.dashboardFilter.filter.showAll = 'active';
    this.dashboardFilter.filter.page = '1';
    this.dashboardFilter.publishFilter();
  }

  public handleCsvExport() {
    const queryParams = this.toQueryParams(this.dashboardFilter.filter);

    this.reportService.downloadDashboardCsvReport(queryParams).subscribe((data) => {
      saveAs(data, `dashboard-report-${moment().format('YYYY-MMM-DD')}.csv`);
    }, err => this.errorHandler.handleHttpError(err));
  }

  public handleSortChange(sortField: string) {
    if (sortField !== this.dashboardFilter.filter.sortBy) {
      this.dashboardFilter.filter.sortBy = sortField as IFormsQueryParams['sortBy'];
      this.dashboardFilter.filter.order = 'asc';
    } else {
      this.dashboardFilter.filter.order = this.dashboardFilter.filter.order === 'asc' ? 'desc' : 'asc';
    }
    this.dashboardFilter.publishFilter();
  }
}
