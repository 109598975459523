import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { FormService } from "../../service/FormService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { IFormOutputModel } from "../../../common/contracts/form";
import { CurrentUserService } from "../../service/currentUser/CurrentUserService";
import { EmailRegex } from "../../../common/constants";
import { Session } from "../../service/util/Session";
import { Router } from "@angular/router";
import { environment } from '../../environments/environment';

export type CustomButton = {
	class?: string;
	icon?: string;
	text?: string;
	routerLink?: string;
	click?: (evt: MouseEvent) => void;
};

@Component({
	selector: 'form-actions',
	styles: [`
    i.fa-circle-o-notch {
        margin-right: 10px;
    }
  `],
	template: `
    <div class="row">
        <div class="col-12 pb-3">
					<button 
						*ngFor="let button of customButtons"
						class="btn btn-{{button.class}} mr-3"
						(click)="button.click ? button.click($event) : null"
						[routerLink]="button.routerLink ? button.routerLink : null"
					>
							<i class="fa fa-{{button.icon}}" *ngIf="button.icon"></i><span *ngIf="button.icon">&nbsp;&nbsp;</span>{{button.text || 'Button'}}
					</button>

					<button *ngIf="isAdminOrManager && !!formData && !formData.isDeleted && !!formData.archivedAt"
						class="btn btn-primary send-report mr-3"
						(click)="handleOpenSendFormReport(sendReportContent)"><i class="fa fa-envelope"></i>&nbsp;&nbsp;Send Email Report</button>
					<button *ngIf="isAdministrator && !isProduction"
						class="btn btn-warning pull-right mr-3"
						(click)="handleFormRevert()"><i class="fa fa-alert"></i>&nbsp;&nbsp;Revert Form</button>
					<button *ngIf="isAdministrator && !!formData && !formData.isDeleted"
						class="btn btn-danger pull-right mr-3"
						(click)="handleDeleteForm(deleteFormContent)"><i class="fa fa-trash"></i>&nbsp;&nbsp;Delete Form</button>
					<button  *ngIf="isAdminOrManager && !!formData && showDuplicateButton"
						class="btn btn-success pull-right mr-3"
						(click)="handleDuplicateForm()"><i class="fa fa-trash"></i>&nbsp;&nbsp;Duplicate Form</button>
        </div>
    </div>
    <div class="row" *ngIf="!!formData && formData.isDeleted">
        <div class="col-12 pb-3">
            <div class="alert alert-danger">This form has been deleted. Deletion reason: {{formData.deleteReason}}</div>
        </div>
    </div>
    <ng-template #deleteFormContent let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Warning</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label for="deleteReason">Enter deletion reason:</label>
            <div class="form-group">
                <textarea
                    id="deleteReason"
                    class="form-control" 
                    [(ngModel)]="deleteReason" 
                    ngbAutofocus
                    autosize
                    [maxLength]="255"
                    required
                    [minRows]="2"></textarea>
                <small class="form-text text-secondary">The text should no be longer than 255 characters</small>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-danger" 
                    (click)="modal.close('confirmed')"
                    [disabled]="deleteReason.trim().length  === 0"
                    ngbAutofocus>Delete</button>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        </div>
    </ng-template>
    <ng-template #sendReportContent let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Send Email Report</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label for="deleteReason">Enter list of emails:</label>
            <div class="form-group">
                <textarea
                        id="deleteReason"
                        class="form-control"
                        [(ngModel)]="emailsList"
                        (input)="validateEmails()"
                        ngbAutofocus
                        autosize
                        [maxLength]="1024"
                        [disabled]="isSendingReport"
                        required
                        [minRows]="2"></textarea>
                <small class="form-text" [ngClass]="{'text-secondary': isEmailsValid, 'text-danger': !isEmailsValid}">Should contain list of valid comma-separated emails.</small>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-primary"
                    (click)="sendFormReport(modal)"
                    [disabled]="isSendingReport || emailsList.trim().length === 0 || !isEmailsValid"
                    ngbAutofocus><i class="fa fa-circle-o-notch fa-spin" *ngIf="isSendingReport"></i>{{isSendingReport ? 'Sending' : 'Send'}}</button>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        </div>
    </ng-template>
  `
})
export class FormActionsComponent implements OnInit {

	@Input()
	formData: IFormOutputModel;

	@Input()
	showDuplicateButton: boolean;

	@Input()
	customButtons: CustomButton[] = [];

	public deleteReason: string = '';
	public emailsList: string = '';
	public isEmailsValid: boolean = true;
	public isAdministrator: boolean = false;
	public isAdminOrManager: boolean = false;
	public isSendingReport: boolean = false;
	public isProduction: boolean = false;

	constructor(
		private ngbModalService: NgbModal,
		private formsService: FormService,
		private location: Location,
		private errorHandler: ErrorHandlerService,
		private currentUserService: CurrentUserService,
		private session: Session,
		private router: Router,
	) { }

	public ngOnInit() {
		this.isAdministrator = this.currentUserService.isAdministrator.getValue();
		this.isProduction = !!environment.production;

		this.currentUserService.currentUserData.subscribe(userInfo => {
			if (userInfo)
				this.isAdminOrManager = ['administrator', 'manager'].includes(userInfo.role);
		});

	}

	public handleDuplicateForm() {
		// Run the passed form duplication function
		if (this.formData.category.name === 'Follow-Up') {
			this.session.lockInputRx(this.formsService.duplicateFollowUp(this.formData.id))
				.subscribe((result: any) => {
					this.router.navigate(['/dashboard']);
				});
		} else {
			throw (`Unknown Form Category for Duplication ${this.formData.category.name}`);
		}
	}

	public handleDeleteForm(content) {
		this.ngbModalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			if (result === 'confirmed') {
				const params = {
					id: this.formData.id,
					deleteReason: this.deleteReason,
				};
				this.formsService.deleteForm(params).subscribe(() => {
					this.location.back();
				}, err => this.errorHandler.handleHttpError(err));
			}
		}, () => { });
	}

	public handleFormRevert() {
		this.formsService.revertForm({ id: this.formData.id }).subscribe(() => {
			this.location.back();
		}, err => this.errorHandler.handleHttpError(err));
	}

	public handleOpenSendFormReport(content) {
		this.ngbModalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(() => {
		}, () => { });
	}

	public sendFormReport(modal) {
		this.isSendingReport = true;
		const params = {
			id: this.formData.id,
			emails: this.emailsList.split(',').map(email => email.trim()),
		};
		this.formsService.sendFormReport(params).subscribe(() => {
			this.emailsList = '';
			this.isSendingReport = false;
			modal.close('');
		}, err => {
			this.errorHandler.handleHttpError(err);
			this.isSendingReport = false;
		});
	}

	public validateEmails() {

		const emails = this.emailsList.split(',').map(email => email.trim()).filter(email => email !== '');

		if (emails.length === 0) {
			this.isEmailsValid = false;
			return;
		}

		for (let email of emails) {
			if (!EmailRegex.test(email)) {
				this.isEmailsValid = false;
				return;
			}
		}

		this.isEmailsValid = true;

	}

	public handleNewRiskAssessment() {
		if (this.formData.category.name !== 'Risk Task') {
			console.log('Invalid form type cannot navigate.');
			return;
		}
		this.router.navigate(['/risk-assessment-task']);
	}
}
