import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ArbitraryValueCache } from 'service/util/ArbitraryValueCache';
import { ICategoryOutputModel } from '../../../../../common/contracts/category';
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
import { FormField } from 'model/Form';
import { ActivatedRoute, Router } from '@angular/router';

export type DashboardFilter = {
    page: string,
    pageSize?: string,
    search: string,
    originatorName: string,
    sortBy: "dueAt" | "createdAt" | "archivedAt" | "location" | "assignedTo" | "summary" | "category" | "documentIdentity",
    order: string,
    assignedUserId?: string,
    category?: string,
    showAll?: "all" | "complete" | "active" | "deleted",
    dueFilter?: "alert" | "overdue" | "remaining",
    locationId?: string,
    groupId?: string,
    reportFormType?: string,
    dueAtStart?: string,
    dueAtEnd?: string,
    createdAtStart?: string,
    createdAtEnd?: string
};

@Component({
    moduleId: module.id,
    selector: 'dashboard-filter',
    templateUrl: 'dashboard-filter.component.html',
    styleUrls: ['dashboard-filter.component.scss']
})
export class DashboardFilterComponent {

    static CACHE_KEY = 'DashboardFilter';

    protected cacheKey = DashboardFilterComponent.CACHE_KEY;

    public showAllOptions = [
        { value: 'all', text: 'All' },
        { value: 'complete', text: 'Complete' },
        { value: 'active', text: 'Active' },
    ];

    static DEFAULT_FILTER: DashboardFilter = {
        page: '1',
        search: '',
        originatorName: '',
        sortBy: 'dueAt',
        order: 'asc'
    };

    public filter: DashboardFilter = {
        page: '1',
        search: '',
        originatorName: '',
        sortBy: 'dueAt',
        order: 'asc'
    };

    public dateFilterMap = {
        none: 'Filter By Date',
        createdAt: 'Created At',
        dueAt: 'Due Date',
    };

    public filterStartDate = new FormField<string>('', {
        validation: FormField.ValidationMethods.None,
        onChange: val => {
            if (val === null) {
                this.filter[this.selectedDateFilter + 'Start'] = '';
            } else {
                this.filter[this.selectedDateFilter + 'Start'] = val.replace(/\//g, '-');
            }

            this.publishFilter();
        }
    });

    public filterEndDate = new FormField<string>('', {
        validation: FormField.ValidationMethods.None,
        onChange: val => {
            if (val === null) {
                this.filter[this.selectedDateFilter + 'End'] = '';
            } else {
                this.filter[this.selectedDateFilter + 'End'] = val.replace(/\//g, '-');
            }

            this.publishFilter();
        }
    });

    public assignedUserId = new FormField<string>('', {
        validation: FormField.ValidationMethods.None,
        onChange: val => {
            if (val) {
                this.filter.assignedUserId = val;
            } else {
                this.filter.assignedUserId = '';
            }

            this.publishFilter();
        }
    });

    public locations = new FormField<string>('', {
        validation: FormField.ValidationMethods.None,
        onChange: val => {
            if (val) {
                this.filter.locationId = val;
            } else {
                this.filter.locationId = '';
            }

            this.publishFilter();
        }
    });

    public groups = new FormField<string>('', {
        validation: FormField.ValidationMethods.None,
        onChange: val => {
            if (val) {
                this.filter.groupId = val;
            } else {
                this.filter.groupId = '';
            }

            this.publishFilter();
        }
    });

    public category = new FormField<string>('', {
        validation: FormField.ValidationMethods.None,
        onChange: val => {
            if (val) {
                this.filter.category = val;
            } else {
                this.filter.category = '';
            }

            this.publishFilter();
        }
    });

    public reportFormType = new FormField<string>('', {
        validation: FormField.ValidationMethods.None,
        onChange: val => {
            if (val) {
                this.filter.reportFormType = val;
            } else {
                this.filter.reportFormType = '';
            }

            this.publishFilter();
        }
    });

    public isAdmin: boolean;
    public isAdminOrManager: boolean;
    public formCategories: Observable<ICategoryOutputModel[]>;
    public selectedDateFilter = 'none';

    get defaultFilterCategoryText() {
        return 'All Categories';
    }

    @Output()
    filterChange = new EventEmitter<DashboardFilter>();

    public constructor(
        private currentUserService: CurrentUserService,
        private arbitraryValueCache: ArbitraryValueCache,
        private route: ActivatedRoute,
        public router: Router,
    ) { }

    ngOnInit() {
        const currentQueryParams = this.route.snapshot.queryParams;
        const currentFilterData = this.arbitraryValueCache.get(this.cacheKey) || {};
        Object.assign(this.filter, currentFilterData, currentQueryParams);

        this.isAdmin = this.currentUserService.isAdministrator.getValue();
        this.isAdminOrManager = this.isAdmin || this.currentUserService.userData!.role === 'manager';

        if (this.isAdmin) {
            this.showAllOptions.splice(2, 0, { value: 'deleted', text: 'Deleted' });
        }

        if (this.isAdminOrManager) {
            if (!this.filter.showAll) {
                this.filter.showAll = 'active';
            }

            if (this.filter.assignedUserId === undefined) {
                const currentUserId = this.currentUserService.currentUserId.getValue();
                if (currentUserId) {
                    const userIdStr = String(currentUserId);
                    this.assignedUserId.value = userIdStr;
                }
            } else {
                this.assignedUserId.patch(this.filter.assignedUserId);
            }

            if (this.filter.locationId === undefined) {
                // All locations for the current user
                this.locations.value = (this.currentUserService.currentUserData.value!.locations || [])
                    .map(location => location.id.toString())
                    .join(",");
            } else {
                this.locations.patch(this.filter.locationId);
            }

            if (this.filter.groupId === undefined) {
                // All locations for the current user
                this.groups.value = (this.currentUserService.currentUserData.value!.groups || [])
                    .map(group => group.id.toString())
                    .join(",");
            } else {
                this.groups.patch(this.filter.groupId);
            }
        } else {
            delete this.filter.showAll;
            delete this.filter.assignedUserId;
            delete this.filter.locationId;
            delete this.filter.groupId;
        }

        if (this.filter.category) {
            this.category.patch(this.filter.category);
        }

        if (this.filter.reportFormType) {
            this.reportFormType.patch(this.filter.reportFormType);
        }

        if (this.filter.dueAtEnd || this.filter.dueAtStart) {
            this.selectedDateFilter = 'dueAt';
            delete this.filter.createdAtEnd;
            delete this.filter.createdAtStart;
            if (this.filter.dueAtEnd) {
                this.filterEndDate.value = this.filter.dueAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.dueAtStart) {
                this.filterStartDate.value = this.filter.dueAtStart.replace(/\-/g, '/');
            }
        } else if (this.filter.createdAtEnd || this.filter.createdAtStart) {
            this.selectedDateFilter = 'createdAt';
            delete this.filter.dueAtEnd;
            delete this.filter.dueAtStart;
            if (this.filter.createdAtEnd) {
                this.filterEndDate.value = this.filter.createdAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.createdAtStart) {
                this.filterStartDate.value = this.filter.createdAtStart.replace(/\-/g, '/');
            }
        }

        // Load from URL on init
        // Write URL to cache

        // Listen to caches in the cache
        this.arbitraryValueCache.onChange(this.cacheKey).subscribe(filter => {
            this.filter = filter as DashboardFilter;

            this.filterChange.emit(this.filter);
        });

        // The filter is ready for interaction
        this.publishFilter();
    }

    public setDefaultFilter() {
        this.filter = Object.assign({}, DashboardFilterComponent.DEFAULT_FILTER);

        if (this.isAdminOrManager) {
            if (!this.filter.showAll) {
                this.filter.showAll = 'active';
            }

            if (this.filter.assignedUserId === undefined) {
                const currentUserId = this.currentUserService.currentUserId.getValue();
                if (currentUserId) {
                    const userIdStr = String(currentUserId);
                    this.assignedUserId.value = userIdStr;
                }
            } else {
                this.assignedUserId.patch(this.filter.assignedUserId);
            }

            if (this.filter.locationId === undefined) {
                // All locations for the current user
                this.locations.value = (this.currentUserService.currentUserData.value!.locations || [])
                    .map(location => location.id.toString())
                    .join(",");
            } else {
                this.locations.patch(this.filter.locationId || null);
            }

            if (this.filter.groupId === undefined) {
                // All locations for the current user
                this.groups.value = (this.currentUserService.currentUserData.value!.groups || [])
                    .map(group => group.id.toString())
                    .join(",");
            } else {
                this.groups.patch(this.filter.groupId);
            }
        } else {
            delete this.filter.showAll;
            delete this.filter.assignedUserId;
            delete this.filter.locationId;
            delete this.filter.groupId;
        }

        this.category.patch(this.filter.category || null);
        this.reportFormType.patch(this.filter.reportFormType || null);

        if (this.filter.dueAtEnd || this.filter.dueAtStart) {
            this.selectedDateFilter = 'dueAt';
            delete this.filter.createdAtEnd;
            delete this.filter.createdAtStart;
            if (this.filter.dueAtEnd) {
                this.filterEndDate.value = this.filter.dueAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.dueAtStart) {
                this.filterStartDate.value = this.filter.dueAtStart.replace(/\-/g, '/');
            }
        } else if (this.filter.createdAtEnd || this.filter.createdAtStart) {
            this.selectedDateFilter = 'createdAt';
            delete this.filter.dueAtEnd;
            delete this.filter.dueAtStart;
            if (this.filter.createdAtEnd) {
                this.filterEndDate.value = this.filter.createdAtEnd.replace(/\-/g, '/');
            }
            if (this.filter.createdAtStart) {
                this.filterStartDate.value = this.filter.createdAtStart.replace(/\-/g, '/');
            }
        }

        this.router.navigate(['.'], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: this.filter,
            queryParamsHandling: '',
        }).then(() => {
            window.location.reload();
        });
    }

    public publishFilter() {
        // Write to URL
        if (!this.filter.createdAtStart) delete this.filter.createdAtStart;
        if (!this.filter.createdAtEnd) delete this.filter.createdAtEnd;
        if (!this.filter.dueAtStart) delete this.filter.dueAtStart;
        if (!this.filter.dueAtEnd) delete this.filter.dueAtEnd;

        const keyDiff = this.arbitraryValueCache.keyDiff(this.cacheKey, this.filter);
        const filterDiff = keyDiff.filter(k => !['page','skip'].includes(k.key));

        if( filterDiff.length > 0 ) {
            this.filter.page = '1';
        }

        this.arbitraryValueCache.set(this.cacheKey, this.filter);
        this.writeFilterToUrl();
    }

    private writeFilterToUrl() {

        this.router.navigate(['.'], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParams: this.filter,
            queryParamsHandling: '',
        });

    }

    public clearSearch() {
        this.filter.search = '';
        this.publishFilter();
    }

    public clearOriginatorSearch() {
        this.filter.originatorName = '';
        this.publishFilter();
    }

    public mapCategoryName(name: string) {
        if (name.toLowerCase() === 'report') return "Feedback";

        return name;
    }

    public handleShowAllFilterChange(option: 'all' | 'complete' | 'deleted' | 'active') {
        this.filter.showAll = option;

        /**
         * if sorting by archived date was active and a user switched to active filter, turn sort by dueAt as default
         */
        if (this.filter.sortBy === 'archivedAt' && option === 'active') {
            this.filter.sortBy = 'dueAt';
            this.filter.order = 'asc';
        }

        this.publishFilter();
    }

    public handleDateFilterChange(value: string) {
        this.selectedDateFilter = value;
        this.filterStartDate.value = '';
        this.filterEndDate.value = '';
        delete this.filter.createdAtStart;
        delete this.filter.createdAtEnd;
        delete this.filter.dueAtStart;
        delete this.filter.dueAtEnd;
        this.publishFilter();
    }
}