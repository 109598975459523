
    <div class="row">
        <div class="col-12 pb-3">
					<button 
						*ngFor="let button of customButtons"
						class="btn btn-{{button.class}} mr-3"
						(click)="button.click ? button.click($event) : null"
						[routerLink]="button.routerLink ? button.routerLink : null"
					>
							<i class="fa fa-{{button.icon}}" *ngIf="button.icon"></i><span *ngIf="button.icon">&nbsp;&nbsp;</span>{{button.text || 'Button'}}
					</button>

					<button *ngIf="isAdminOrManager && !!formData && !formData.isDeleted && !!formData.archivedAt"
						class="btn btn-primary send-report mr-3"
						(click)="handleOpenSendFormReport(sendReportContent)"><i class="fa fa-envelope"></i>&nbsp;&nbsp;Send Email Report</button>
					<button *ngIf="isAdministrator && !isProduction"
						class="btn btn-warning pull-right mr-3"
						(click)="handleFormRevert()"><i class="fa fa-alert"></i>&nbsp;&nbsp;Revert Form</button>
					<button *ngIf="isAdministrator && !!formData && !formData.isDeleted"
						class="btn btn-danger pull-right mr-3"
						(click)="handleDeleteForm(deleteFormContent)"><i class="fa fa-trash"></i>&nbsp;&nbsp;Delete Form</button>
					<button  *ngIf="isAdminOrManager && !!formData && showDuplicateButton"
						class="btn btn-success pull-right mr-3"
						(click)="handleDuplicateForm()"><i class="fa fa-trash"></i>&nbsp;&nbsp;Duplicate Form</button>
        </div>
    </div>
    <div class="row" *ngIf="!!formData && formData.isDeleted">
        <div class="col-12 pb-3">
            <div class="alert alert-danger">This form has been deleted. Deletion reason: {{formData.deleteReason}}</div>
        </div>
    </div>
    <ng-template #deleteFormContent let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Warning</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label for="deleteReason">Enter deletion reason:</label>
            <div class="form-group">
                <textarea
                    id="deleteReason"
                    class="form-control" 
                    [(ngModel)]="deleteReason" 
                    ngbAutofocus
                    autosize
                    [maxLength]="255"
                    required
                    [minRows]="2"></textarea>
                <small class="form-text text-secondary">The text should no be longer than 255 characters</small>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-danger" 
                    (click)="modal.close('confirmed')"
                    [disabled]="deleteReason.trim().length  === 0"
                    ngbAutofocus>Delete</button>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        </div>
    </ng-template>
    <ng-template #sendReportContent let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Send Email Report</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label for="deleteReason">Enter list of emails:</label>
            <div class="form-group">
                <textarea
                        id="deleteReason"
                        class="form-control"
                        [(ngModel)]="emailsList"
                        (input)="validateEmails()"
                        ngbAutofocus
                        autosize
                        [maxLength]="1024"
                        [disabled]="isSendingReport"
                        required
                        [minRows]="2"></textarea>
                <small class="form-text" [ngClass]="{'text-secondary': isEmailsValid, 'text-danger': !isEmailsValid}">Should contain list of valid comma-separated emails.</small>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-primary"
                    (click)="sendFormReport(modal)"
                    [disabled]="isSendingReport || emailsList.trim().length === 0 || !isEmailsValid"
                    ngbAutofocus><i class="fa fa-circle-o-notch fa-spin" *ngIf="isSendingReport"></i>{{isSendingReport ? 'Sending' : 'Send'}}</button>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        </div>
    </ng-template>
  