import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { IFormOutputModel } from "../../../../../../common/contracts/form";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from 'views/shared/followUpWidget.component';
import { IDocumentType } from '../../../../../../common/contracts/document';
import { Router } from '@angular/router';
import { riskTypeMap, riskTypeOptions } from '../../../../../model/RiskType';
import { logger } from 'service/util/Logger';
import { IEnumsOutputModel } from '../../../../../../common/contracts/enums';
import { EnumService } from 'service/EnumService';
import { FollowUpService } from 'service/FollowUpService';
@Component({
	selector: 'risk-assessment-form-3',
	templateUrl: './riskAssessmentFormStage3.component.html'
})
export class RiskAssessmentFormStage3Component extends FormComponent implements OnInit {
	public currentFormStage: number = 3;
	public className = "RiskAssessmentFormStage3Component";
	public documents: Array<IDocumentType> = [];
	public riskType: string = 'General Risk';
	public followUpFormTypeId: number | null = null;
	public riskTypeOptions = riskTypeOptions;

	// When true this form will assign to the department head for signoff instead
	private departmentUserGroupId: number | null = null;

	// Existing Form Data
	@Input() public formData: IFormOutputModel;
	@Input() sequence: number;

	public formRecord: IFormRecordOutputModel;
	public isExistingRisk: boolean = false;

	@Input() readOnly: boolean = false;
	@Input() hideHeader: boolean = false;

	@ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

	/*
		This should have been done properly using something that implements FormControl but its
		too late now
	 */
	public form: { [key: string]: FormField<any> } = {
		reassign: new FormField<boolean>(false, {
			validation: FormField.ValidationMethods.None
		}),
		//Since this will be assigned to a select it must be a string data - Conversion where appropriate
		reassignToUserId: new FormField<string>('', {
			validation: FormField.ValidationMethods.None
		}),
		followUps: new FormField<string>('[]', {
			nullEquivilent: "[]",
			recordParamType: RecordParamType.JSON,
			validation: (value: string) => {
				return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
			}
		}),
		summary: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
	};

	constructor(
		public session: Session,
		public currentUserService: CurrentUserService,
		public formRecordService: FormRecordService,
		public formService: FormService,
		public errorHandler: ErrorHandlerService,
		public router: Router,
		public enumService: EnumService,
		public followUpService: FollowUpService
	) {
		super(router);
	}

	registerFormFields() {
		this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
	}

	ngOnInit() {
		this.loadEnums();
		this.registerFormFields();
		this.repopulateFormFromData();
	}

	onSubmit(isDraft: boolean = false) {
		const signature = this.className + ".onSubmit:";
		this.session.lockInput(() => {
			return new Promise(async (resolve, reject) => {
				let assignedUserId: number | null = this.currentUserService.userData && isDraft ? this.currentUserService.userData.id : null;

				let stage: number = this.currentFormStage;
				let userGroupId: number | null = null;
				if (!isDraft) {
					if (this.form.reassign.value && Number(this.form.reassignToUserId.value)) {
						// The user is intending to reassign this form to another user
						stage = 1;
						userGroupId = this.departmentUserGroupId;
						assignedUserId = Number(this.form.reassignToUserId.value);
						logger.silly(signature + `Assigning form to User[${assignedUserId}] and Group[${userGroupId}]`);
					} else {
						stage = 4;
						userGroupId = this.departmentUserGroupId;
						logger.silly(signature + `Assigning form to User[${assignedUserId}] and Group[${userGroupId}]`);
					}
				}

				this.formService.updateForm({
					id: this.formData.id,
					formLocationId: this.formData.formLocationId,
					userGroupId,
					notifyOnComplete: this.formData.notifyOnComplete,
					stage,
					assignedUserId
				}).subscribe((data: any) => {
					const properties = this.toRecordParams(this.form);

					this.formRecordService.createRecord({
						formId: this.formData.id,
						properties: properties as any,
						stage: this.currentFormStage,
						documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })), // send list of attached documents
						isComplete: !isDraft
					})
						.subscribe((data: any) => {
							const success = () => {
								resolve(data);

								this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'ram-dashboard' });
							}

							//Done creating the form and appending its properties
							if (stage === 4) {
								this.formService.finalizeForm(this.formData.id).subscribe(() => {
									let followUps: { userGroupId: string, description: string, dueDate: string }[] = [];

									if (this.form.followUps.value && this.form.followUps.value.length) {
										followUps = JSON.parse(this.form.followUps.value);
									}

									if (followUps.length === 0)
										return success();

									this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, this.followUpFormTypeId, success);
								});
							} else {
								success();
							}
						}, err => {
							this.errorHandler.handleHttpError(err);
							reject("Error creating new record");
						});
				}, (err) => {
					this.errorHandler.handleHttpError(err);
					reject("Error updating form");
				});
			});
		});
	}

	/**
	 * @description Fetches the original data from stage0
	 */
	private handleInitialData(): void {
		const lastStage0Submission = this.getMostRecentSubmission(this.formData, false, 0);
		const firstAssessment = this.getOldestSubmission(this.formData, false, 1, undefined);
		const lastAssessment = this.getMostRecentSubmission(this.formData, false, 1, undefined);

		if (!lastStage0Submission) {
			throw new Error("There should always be a stage 0 submission");
		}

		if (!firstAssessment) {
			throw new Error("There should always be a first 1 submission");
		}

		if (!lastAssessment) {
			throw new Error("There should always be a last 1 submission");
		}

		if (lastStage0Submission) {

			const propsOfInterest = {
				riskType: new FormField<string>('1', {
					recordParamType: RecordParamType.Number
				}),
				isExistingRisk: new FormField<boolean>(false, {
					recordParamType: RecordParamType.Boolean
				}),
				existingRisk: new FormField<number>(null, {
					validation: FormField.ValidationMethods.None
				})
			};

			this.updateFromRecordParams(propsOfInterest, lastStage0Submission);
			this.riskType = riskTypeMap[(propsOfInterest.riskType.value || '1').toString()];
			this.isExistingRisk = !!(propsOfInterest.isExistingRisk.value && propsOfInterest.existingRisk.value);
		} else {
			throw new Error("There should always be a stage 0 submission");
		}
	}

	private async repopulateFormFromData() {
		this.handleInitialData();

		if (this.sequence) {
			const sequenceSubmission = this.getMostRecentSubmission(this.formData, false, this.currentFormStage, this.sequence);

			return this.reloadExistingData(sequenceSubmission);
		}

		const lastStage0Submission = this.getMostRecentSubmission(this.formData, false, 0);
		if (!lastStage0Submission) {
			throw new Error("There should always be a stage 0 submission");
		}
		this.departmentUserGroupId = this.getIntData(lastStage0Submission, 'initialAssessorDepartmentId');

		const lastSubmission = this.getMostRecentSubmission(this.formData, true, this.currentFormStage);
		if (lastSubmission && !lastSubmission.isComplete) {
			// We have draft Data
			return this.reloadExistingData(lastSubmission);
		}

		return this.loadFollowUpData();
	}

	/**
	 * @description Loads follow up data from the most recent submission of any type
	 */
	private loadFollowUpData() {
		const signature = this.className + ".loadFollowUpData: ";
		if (!this.form.followUps.valueIsNull) {
			logger.silly(signature + `FollowUp Data Contains[${this.form.followUps.value}] and will not be loaded from previous submissions`);
			return;
		}

		const mostRecentSubmission = this.getMostRecentSubmission(this.formData, true);

		if (mostRecentSubmission) {
			const followUpData = this.getJsonData(mostRecentSubmission, 'followUps');
			this.form.followUps.value = followUpData;
		} else {
			logger.silly(signature + `Most recent submission not found`);
		}
	}

	/**
	 * @description Handles an incoming submission record by loading it into the form
	 * @param {IFormRecordOutputModel|null} submission Allows null for convenience, does nothing if null is supplied
	 * @returns {void}
	 */
	private reloadExistingData(submission: IFormRecordOutputModel | null): void {
		if (!submission) return;

		this.formRecord = submission;
		this.updateFromRecordParams(this.form, this.formRecord);
		this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
	}

	private loadEnums() {
		this.session.lockInputRx(this.enumService.getEnumsByName('reportFormType'))
			.subscribe((data: IEnumsOutputModel[]) => {
				const followUpFormType = data.find(typeEnum => typeEnum.value.toLowerCase() === 'other');
				if (followUpFormType) {
					this.followUpFormTypeId = followUpFormType.id;
				}
			});
	}
}
