var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { Router } from "@angular/router";
import { riskTypeMap } from "../../../../../model/RiskType";
import { UsersService } from "service/admin/UsersService";
import { logger } from "service/util/Logger";
import { Risk } from "../../../../../../common/Risk";
import { ModalService } from "service/ModalService";
import { EnumService } from "service/EnumService";
import { FollowUpService } from "service/FollowUpService";
var RiskAssessmentFormStage1Component = /** @class */ (function (_super) {
    __extends(RiskAssessmentFormStage1Component, _super);
    function RiskAssessmentFormStage1Component(session, currentUserService, formRecordService, formService, groupsService, errorHandler, router, usersService, modalService, enumService, followUpService) {
        var _this = _super.call(this, router) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formRecordService = formRecordService;
        _this.formService = formService;
        _this.groupsService = groupsService;
        _this.errorHandler = errorHandler;
        _this.router = router;
        _this.usersService = usersService;
        _this.modalService = modalService;
        _this.enumService = enumService;
        _this.followUpService = followUpService;
        _this.currentFormStage = 1;
        _this.className = "RiskAssessmentFormStage1Component";
        _this.followUpFormTypeId = null;
        _this.readOnly = false;
        _this.hideHeader = false;
        /*
            This should have been done properly using something that implements FormControl but its
            too late now
         */
        _this.form = {
            riskRemoved: new FormField(false, {
                validation: FormField.ValidationMethods.None,
                onChange: function () { _this.updateFormValidation(); },
                recordParamType: RecordParamType.Boolean
            }),
            riskLevelChanged: new FormField(false, {
                isVisible: false,
                validation: FormField.ValidationMethods.None,
                onChange: function () { _this.updateFormValidation(); },
                recordParamType: RecordParamType.Boolean
            }),
            reassign: new FormField(false, {
                isVisible: false,
                validation: FormField.ValidationMethods.None,
                onChange: function () { _this.updateFormValidation(); },
                recordParamType: RecordParamType.Boolean
            }),
            //Since this will be assigned to a select it must be a string data - Conversion where appropriate
            reassignToUserId: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            /* Initial Severity Calculations */
            severity: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number,
                onChange: function () {
                    _this.calculateRiskLevel(_this.form.occuranceLikelyhood, _this.form.severity, _this.form.riskLevel);
                }
            }),
            occuranceLikelyhood: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number,
                onChange: function () {
                    _this.calculateRiskLevel(_this.form.occuranceLikelyhood, _this.form.severity, _this.form.riskLevel);
                }
            }),
            riskLevel: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number
            }),
            mitigationMeasures: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            postMitigationSeverity: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number,
                onChange: function () {
                    _this.calculateRiskLevel(_this.form.postMitigationOccuranceLikelyhood, _this.form.postMitigationSeverity, _this.form.postMitigationRiskLevel);
                }
            }),
            postMitigationOccuranceLikelyhood: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number,
                onChange: function () {
                    _this.calculateRiskLevel(_this.form.postMitigationOccuranceLikelyhood, _this.form.postMitigationSeverity, _this.form.postMitigationRiskLevel);
                }
            }),
            postMitigationRiskLevel: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number
            }),
            recommendations: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            howResidentHasBeenInformed: new FormField('', {
                isVisible: false,
                validation: FormField.ValidationMethods.None
            }),
            followUps: new FormField('[]', {
                nullEquivilent: "[]",
                recordParamType: RecordParamType.JSON,
                validation: function (value) {
                    return _this.followUpWidgetRef ? _this.followUpWidgetRef.validate() : true;
                }
            }),
            summary: new FormField('', {
                isVisible: false,
                validation: FormField.ValidationMethods.None
            }),
        };
        _this.documents = [];
        _this.riskType = 'General Risk';
        _this.isExistingRisk = false;
        _this.isResidentRisk = false;
        // When true this form will assign to the department head for signoff instead
        _this.departmentUserGroupId = null;
        _this.likelihoodSelectOptions = [{ id: '', text: '' }].concat(Risk.LikelihoodOptions);
        _this.severitySelectOptions = [{ id: '', text: '' }].concat(Risk.SeverityOptions);
        _this.riskSelectOptions = [{ id: '', text: '' }].concat(Risk.RiskOptions);
        return _this;
    }
    /**
     * @description Syncs form validation against state data
     */
    RiskAssessmentFormStage1Component.prototype.updateFormValidation = function () {
        var signature = this.className + '.updateFormValidation: ';
        this.resetAllValidation();
        this.form.reassign.isVisible = this.currentUserService.isAdministratorOrManager.getValue();
        logger.silly(signature + "Updating Form Validation");
        if (this.form.reassign.value) {
            this.setFieldValidation(this.form.severity, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.occuranceLikelyhood, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.riskLevel, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.mitigationMeasures, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.postMitigationSeverity, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.postMitigationOccuranceLikelyhood, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.postMitigationRiskLevel, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.recommendations, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.howResidentHasBeenInformed, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
            this.form.mitigationMeasures.isVisible = false;
            this.form.recommendations.isVisible = false;
            this.form.riskRemoved.isVisible = false;
        }
        if (this.isExistingRisk) {
            this.setFieldValidation(this.form.severity, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.occuranceLikelyhood, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.riskLevel, FormField.ValidationMethods.None);
            this.form.riskLevelChanged.isVisible = true;
        }
        if (this.isResidentRisk) {
            this.setFieldValidation(this.form.howResidentHasBeenInformed, FormField.ValidationMethods.IsNotBlank);
            this.form.howResidentHasBeenInformed.isVisible = true;
        }
        if (!this.form.riskLevelChanged.value) {
            this.setFieldValidation(this.form.severity, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.occuranceLikelyhood, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.mitigationMeasures, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.recommendations, FormField.ValidationMethods.None);
            this.form.severity.isVisible = false;
            this.form.occuranceLikelyhood.isVisible = false;
            this.form.mitigationMeasures.isVisible = false;
            this.form.recommendations.isVisible = false;
            this.form.riskRemoved.isVisible = false;
            this.form.riskRemoved.patch(false);
        }
        if (!this.form.riskLevelChanged.value || this.form.riskRemoved.value) {
            this.setFieldValidation(this.form.postMitigationSeverity, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.postMitigationOccuranceLikelyhood, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.postMitigationRiskLevel, FormField.ValidationMethods.None);
            this.form.postMitigationSeverity.isVisible = false;
            this.form.postMitigationOccuranceLikelyhood.isVisible = false;
            this.form.postMitigationRiskLevel.isVisible = false;
        }
        if (this.form.riskRemoved.value || this.form.reassign.value) {
            this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
        }
        if (!this.form.riskLevelChanged.value || this.form.riskRemoved.value || this.form.reassign.value) {
            this.form.summary.isVisible = true;
        }
        if (this.form.reassign.value) {
            this.form.riskLevelChanged.isVisible = false;
        }
    };
    RiskAssessmentFormStage1Component.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadEnums();
                        this.registerFormFields(this.form);
                        return [4 /*yield*/, this.repopulateFormFromData()];
                    case 1:
                        _a.sent();
                        this.updateFormValidation();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @description Calculates risk level and updates the risk level form item
     * @param {FormField<string>} likelihood
     * @param {FormField<string>} severity
     * @param {FormField<string>} riskOutput Destination field to set the new risk elvel
     */
    RiskAssessmentFormStage1Component.prototype.calculateRiskLevel = function (likelihood, severity, riskOutput) {
        var calculatedRisk = Risk.calculateRisk(likelihood.value, severity.value);
        riskOutput.value = calculatedRisk ? calculatedRisk.id.toString() : null;
    };
    RiskAssessmentFormStage1Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        if (isDraft === void 0) { isDraft = false; }
        var signature = this.className + ".onSubmit:";
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var assignedUserId, stage, userGroupId, isClinicalRisk;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            assignedUserId = this.currentUserService.userData && isDraft ? this.currentUserService.userData.id : null;
                            stage = 1;
                            userGroupId = this.formData.userGroupId;
                            if (!!isDraft) return [3 /*break*/, 5];
                            if (!(this.form.reassign.value && Number(this.form.reassignToUserId.value))) return [3 /*break*/, 1];
                            // The user is intending to reassign this form to another user
                            stage = 1;
                            userGroupId = this.formData.userGroupId;
                            assignedUserId = Number(this.form.reassignToUserId.value);
                            logger.silly(signature + ("Assigning form to User[" + assignedUserId + "] and Group[" + userGroupId + "]"));
                            return [3 /*break*/, 4];
                        case 1:
                            if (!(this.isExistingRisk && !this.form.riskRemoved.value && !this.form.riskLevelChanged)) return [3 /*break*/, 2];
                            // Existing risk, not removed, unchanged risk level
                            stage = 4;
                            userGroupId = this.departmentUserGroupId;
                            return [3 /*break*/, 4];
                        case 2:
                            isClinicalRisk = !!this.riskType.toLowerCase().replace(/[^a-z]/g, '').match(/clinical/);
                            stage = isClinicalRisk ? 2 : 3;
                            return [4 /*yield*/, this.groupsService.getGroupIdByName(isClinicalRisk ? "Clinical Governance" : "Senior Management")];
                        case 3:
                            userGroupId = _a.sent();
                            _a.label = 4;
                        case 4: return [3 /*break*/, 6];
                        case 5:
                            logger.silly(signature + ("Draft will be assigned to User[" + assignedUserId + "] and Group[" + userGroupId + "]"));
                            _a.label = 6;
                        case 6:
                            this.formService.updateForm({
                                id: this.formData.id,
                                formLocationId: this.formData.formLocationId,
                                userGroupId: userGroupId,
                                notifyOnComplete: this.formData.notifyOnComplete,
                                stage: stage,
                                assignedUserId: assignedUserId
                            }).subscribe(function (data) {
                                var properties = _this.toRecordParams(_this.form);
                                _this.formRecordService.createRecord({
                                    formId: _this.formData.id,
                                    properties: properties,
                                    stage: _this.currentFormStage,
                                    documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                                    isComplete: !isDraft
                                })
                                    .subscribe(function (data) {
                                    var success = function () {
                                        resolve(data);
                                        _this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'ram-dashboard' });
                                    };
                                    if (stage === 4) {
                                        _this.formService.finalizeForm(_this.formData.id).subscribe(function () {
                                            var followUps = [];
                                            if (_this.form.followUps.value && _this.form.followUps.value.length) {
                                                followUps = JSON.parse(_this.form.followUps.value);
                                            }
                                            if (followUps.length === 0)
                                                return success();
                                            _this.followUpService.generateFollowUps(_this.formData.id, _this.formData.formLocationId, followUps, _this.followUpFormTypeId, success);
                                        });
                                    }
                                    else {
                                        success();
                                    }
                                }, function (err) {
                                    _this.errorHandler.handleHttpError(err);
                                    reject("Error creating new record");
                                });
                            }, function (err) {
                                _this.errorHandler.handleHttpError(err);
                                reject("Error updating form");
                            });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    /**
     * @description Fetches the original data from stage0
     */
    RiskAssessmentFormStage1Component.prototype.handleInitialData = function () {
        var lastStage0Submission = this.getMostRecentSubmission(this.formData, false, 0);
        if (lastStage0Submission) {
            var propsOfInterest = {
                riskType: new FormField('1', {
                    recordParamType: RecordParamType.Number
                }),
                isExistingRisk: new FormField(false, {
                    recordParamType: RecordParamType.Boolean
                }),
                existingRisk: new FormField(null, {
                    recordParamType: RecordParamType.Number
                }),
                existingRiskLevel: new FormField(null, {}),
            };
            this.updateFromRecordParams(propsOfInterest, lastStage0Submission);
            this.riskType = riskTypeMap[(propsOfInterest.riskType.value || '1').toString()];
            this.isExistingRisk = !!(propsOfInterest.isExistingRisk.value && propsOfInterest.existingRisk.value);
            this.form.riskLevelChanged.patch(!this.isExistingRisk);
            if (this.isExistingRisk) {
                var existingRiskLevel = Risk.getEnumByKey(propsOfInterest.existingRiskLevel.value, Risk.Risk);
                this.form.riskLevel.value = (existingRiskLevel || Risk.Risk.Low).id.toString();
            }
            if (this.riskType.toString().match(/^Resident/)) {
                this.isResidentRisk = true;
            }
            this.departmentUserGroupId = this.getIntData(lastStage0Submission, 'initialAssessorDepartmentId');
            this.updateFormValidation();
        }
        else {
            throw new Error("There should always be a stage 0 submission");
        }
    };
    RiskAssessmentFormStage1Component.prototype.repopulateFormFromData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sequenceSubmission, lastSubmission, mostRecentNonAssignment;
            var _this = this;
            return __generator(this, function (_a) {
                this.handleInitialData();
                if (this.sequence) {
                    sequenceSubmission = this.getMostRecentSubmission(this.formData, false, this.currentFormStage, this.sequence);
                    return [2 /*return*/, this.reloadExistingData(sequenceSubmission)];
                }
                lastSubmission = this.getMostRecentSubmission(this.formData, true, this.currentFormStage);
                if (lastSubmission && !lastSubmission.isComplete) {
                    // We have draft Data
                    return [2 /*return*/, this.reloadExistingData(lastSubmission)];
                }
                mostRecentNonAssignment = this.getMostRecentSubmission(this.formData, false, this.currentFormStage, undefined, function (record) {
                    return !_this.getStringData(record, 'reassignToUserId');
                });
                if (mostRecentNonAssignment) {
                    this.reloadExistingData(mostRecentNonAssignment);
                }
                return [2 /*return*/, this.loadFollowUpData()];
            });
        });
    };
    /**
     * @description Loads follow up data from the most recent submission of any type
     */
    RiskAssessmentFormStage1Component.prototype.loadFollowUpData = function () {
        if (!this.form.followUps.valueIsNull)
            return;
        var mostRecentSubmission = this.getMostRecentSubmission(this.formData, true);
        if (mostRecentSubmission) {
            var followUpData = this.getJsonData(mostRecentSubmission, 'followUps');
            this.form.followUps.value = followUpData;
        }
    };
    /**
     * @description Handles an incoming submission record by loading it into the form
     * @param {IFormRecordOutputModel|null} submission Allows null for convenience, does nothing if null is supplied
     * @returns {void}
     */
    RiskAssessmentFormStage1Component.prototype.reloadExistingData = function (submission) {
        var signature = this.className + ".reloadExistingData: ";
        if (!submission)
            return;
        this.formRecord = submission;
        this.updateFromRecordParams(this.form, this.formRecord);
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        logger.silly(signature + ("Reloaded existing data from Submission[" + submission.id + "] with Sequence[" + submission.sequence + "] in Stage[" + submission.stage + "]"));
    };
    /**
     * @description Handles click events to show the risk matrix
     */
    RiskAssessmentFormStage1Component.prototype.showRiskMatrix = function () {
        this.modalService.risk();
    };
    RiskAssessmentFormStage1Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.enumService.getEnumsByName('reportFormType'))
            .subscribe(function (data) {
            var followUpFormType = data.find(function (typeEnum) { return typeEnum.value.toLowerCase() === 'other'; });
            if (followUpFormType) {
                _this.followUpFormTypeId = followUpFormType.id;
            }
        });
    };
    RiskAssessmentFormStage1Component.prototype.updateRiskLevelChanged = function (riskLevelChanged) {
        this.form.riskLevelChanged.value = riskLevelChanged;
    };
    RiskAssessmentFormStage1Component.prototype.updateRemoveState = function (riskRemoved) {
        this.form.riskRemoved.value = riskRemoved;
    };
    return RiskAssessmentFormStage1Component;
}(FormComponent));
export { RiskAssessmentFormStage1Component };
