import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest } from 'rxjs';
import { CategoryService } from 'service/CategoryService';
import { RiskService } from 'service/RiskService';
import { logger } from 'service/util/Logger';
import { Session } from 'service/util/Session';
import { stringifyObjectValues } from 'service/util/stringifyObjectValues';
import { IRiskOutputModel } from '../../../../../../common/contracts/risk';

@Component({
	selector: 'app-editRisk-component',
	templateUrl: './editRisk.component.html'
})

export class EditRiskComponent implements OnInit {
	private className = "EditRiskComponent";
	public riskId: number;
	public risk: IRiskOutputModel | null = null;

	public navOptions = {
		existingAssessments: false
	};

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private riskService: RiskService,
		private session: Session,
		private categoryService: CategoryService
	) {
	}

	ngOnInit() {
		combineLatest([
			this.activatedRoute.params,
			this.activatedRoute.queryParams
		]).subscribe( ([params, queryParams]) => {
			/** if page param is undefined push default query params  */
			if (!queryParams.page || !queryParams.category) {
				this.pushDefaultQueryParams();
				return;
			}

			this.navOptions.existingAssessments = true;

			if (params.riskId) {
				if (/^\d+$/.test(params.riskId) !== true) {
					console.error('Invalid risk id router param');
					this.router.navigate(['/404'], { replaceUrl: true });
					return;
				}

				this.riskId = parseInt(params.riskId, 10);
			}

			this.riskService.getRiskFromId(this.riskId).subscribe(risk => {
				this.risk = risk;
			});
		});
	}

	/**
	 * @description Writes the params which are used for the dashboard component to the url
	 */
	private pushDefaultQueryParams() {
		const signature = this.className + ".pushDefaultQueryParams: ";
		logger.silly(signature + `Updating Params for Dashboard`);
		// Setting DRM-Task as default category
		combineLatest([
			this.categoryService.getCategories(),
			this.activatedRoute.params
		])
		.subscribe( ([categories, params]) => {
			const drmTaskCategory = categories.find(cat => cat.name === 'Risk Task');
			const queryParams = stringifyObjectValues({
				page: 1,
				showAll: 'all',
				sortBy: 'createdAt',
				order: 'desc',
				group: '',
				category: drmTaskCategory && drmTaskCategory.id,
				riskId: params.riskId
			});

			this.router.navigate(['.'], {
				relativeTo: this.activatedRoute,
				replaceUrl: true,
				queryParams,
				queryParamsHandling: '',
			});
		});
	}

	/**
	 * @description Handles the "New Assessment" button
	 */
	public createNewAssessment() {
		this.riskService.generateAssessment(this.riskId).subscribe(data => {
			this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'ram-dashboard' });
		}, err => {
			console.error(err);
		})
	}

	public updateRisk() {
		if (!this.risk) return;

		const newSummary = this.risk.summary;
		if (newSummary.length > 0) {
			this.riskService.updateRisk({ id: this.riskId, summary: newSummary }).subscribe(data => {
				this.router.navigate([`/risks`], { replaceUrl: true });
			}, err => {
				console.error(err);
			})
		}
	}
}