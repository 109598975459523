var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import * as moment from 'moment';
import 'moment-timezone';
import { FormField, RecordParamType } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { GroupsService } from "service/admin/GroupsService";
import { RiskService } from "service/RiskService";
import { environment } from "../../../../../environments/environment";
import { riskTypeMap, riskTypeOptions } from "../../../../../model/RiskType";
var RiskAssessmentFormStage0Component = /** @class */ (function (_super) {
    __extends(RiskAssessmentFormStage0Component, _super);
    function RiskAssessmentFormStage0Component(session, currentUserService, formService, categoryService, formRecordService, router, errorHandler, groupsService, riskService) {
        var _this = _super.call(this, router) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.formRecordService = formRecordService;
        _this.router = router;
        _this.errorHandler = errorHandler;
        _this.groupsService = groupsService;
        _this.riskService = riskService;
        _this.currentFormStage = 0;
        // Existing Form Data
        _this.readOnly = false;
        _this.hideHeader = false;
        _this.documents = [];
        _this.riskTypeOptions = riskTypeOptions;
        _this.form = {
            /** Required after Refactor */
            residentName: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            location: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number
            }),
            summary: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            riskType: new FormField('1', {
                validation: FormField.ValidationMethods.IsNotBlank,
                onChange: (function (val) { _this.configureValidation(); }).bind(_this),
                recordParamType: RecordParamType.Number
            }),
            isExistingRisk: new FormField(false, {
                validation: FormField.ValidationMethods.IsNotNull,
                onChange: (function (val) { _this.configureValidation(); }).bind(_this),
                recordParamType: RecordParamType.Boolean
            }),
            existingRisk: new FormField(null, {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Number
            }),
            initialAssessorDepartmentId: new FormField(null, {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number
            }),
        };
        return _this;
    }
    RiskAssessmentFormStage0Component.prototype.configureValidation = function (isDraft) {
        if (isDraft === void 0) { isDraft = false; }
        if (isDraft) {
            this.setFieldValidation(this.form.location, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.riskType, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.residentName, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.existingRisk, FormField.ValidationMethods.None);
            return;
        }
        if (this.form.isExistingRisk.value) {
            this.setFieldValidation(this.form.location, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.riskType, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.residentName, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.existingRisk, FormField.ValidationMethods.IsNotBlank);
        }
        else {
            if (this.isResidentRisk()) {
                this.setFieldValidation(this.form.residentName, FormField.ValidationMethods.IsNotBlank);
            }
            else {
                this.setFieldValidation(this.form.residentName, FormField.ValidationMethods.None);
            }
            this.setFieldValidation(this.form.location, FormField.ValidationMethods.IsNotBlank);
            this.setFieldValidation(this.form.riskType, FormField.ValidationMethods.IsNotBlank);
            this.setFieldValidation(this.form.existingRisk, FormField.ValidationMethods.IsBlank);
        }
    };
    RiskAssessmentFormStage0Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    RiskAssessmentFormStage0Component.prototype.beforeSubmit = function (draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        this.configureValidation(draft);
        if (this.form.isExistingRisk.value && this.form.existingRisk.value) {
            this.riskService.getRiskFromId(this.form.existingRisk.value).subscribe(function (risk) {
                // TODO: Set the risk type prop to the value from the risk as well
                _this.form.summary.value = risk.summary;
                _this.submit(draft);
            });
        }
        else {
            this.form.isExistingRisk.value = false;
            this.form.existingRisk.value = null;
            this.submit(draft);
        }
    };
    RiskAssessmentFormStage0Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        if (isDraft === void 0) { isDraft = false; }
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var formLocationId, assignedUserId, stage, userGroupId, finalUserGroupId, _a, initialDeptId, notifyOnComplete, formFunc, dueAt, alertAt;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            formLocationId = this.positiveValidInt(this.form.location);
                            assignedUserId = this.currentUserService.userData && isDraft ? this.currentUserService.userData.id : null;
                            stage = 0;
                            userGroupId = null;
                            finalUserGroupId = null;
                            _a = riskTypeMap[(this.form.riskType.value || 1).toString()].toLowerCase();
                            switch (_a) {
                                case "resident clinical risk": return [3 /*break*/, 1];
                                case "resident non-clinical risk": return [3 /*break*/, 3];
                            }
                            return [3 /*break*/, 5];
                        case 1: return [4 /*yield*/, this.groupsService.getGroupIdByName("Clinical Governance")];
                        case 2:
                            finalUserGroupId = _b.sent();
                            return [3 /*break*/, 7];
                        case 3: return [4 /*yield*/, this.groupsService.getGroupIdByName("Risk Management")];
                        case 4:
                            finalUserGroupId = _b.sent();
                            return [3 /*break*/, 7];
                        case 5: return [4 /*yield*/, this.groupsService.getGroupIdByName("OHSC Management")];
                        case 6:
                            finalUserGroupId = _b.sent();
                            _b.label = 7;
                        case 7:
                            if (!isDraft) {
                                stage = 1;
                                if (this.form.isExistingRisk.value && this.form.existingRisk.value) {
                                    userGroupId = finalUserGroupId;
                                }
                                else {
                                    initialDeptId = this.positiveValidInt(this.form.initialAssessorDepartmentId);
                                    if (initialDeptId) {
                                        userGroupId = initialDeptId;
                                    }
                                }
                            }
                            notifyOnComplete = null;
                            if (!this.isNewForm(this.formData)) {
                                //FormFunc = update
                                formFunc = this.formService.updateForm({
                                    id: this.formData.id,
                                    formLocationId: formLocationId,
                                    userGroupId: userGroupId,
                                    notifyOnComplete: notifyOnComplete,
                                    stage: stage,
                                    assignedUserId: assignedUserId
                                });
                            }
                            else {
                                dueAt = moment().tz(environment.timeZone).add(14, 'days').set({
                                    'hour': 0,
                                    'minute': 0,
                                    'second': 0
                                }).toISOString(false);
                                alertAt = moment().tz(environment.timeZone).add(7, 'days').set({
                                    'hour': 0,
                                    'minute': 0,
                                    'second': 0
                                }).toISOString(false);
                                formFunc = this.formService.createForm({
                                    categoryId: this.formCategoryId,
                                    formLocationId: formLocationId,
                                    userGroupId: userGroupId,
                                    dueAt: dueAt,
                                    alertAt: alertAt,
                                    notifyOnComplete: notifyOnComplete,
                                    stage: stage,
                                    parentFormId: null,
                                    assignedUserId: assignedUserId,
                                    summary: this.form.summary.value,
                                });
                            }
                            formFunc.subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                                var formId, properties;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    formId = (this.formData && this.formData.id) ? this.formData.id : data['id'];
                                    properties = this.toRecordParams(this.form);
                                    if (finalUserGroupId) {
                                        properties.push({
                                            name: 'userGroupId',
                                            intData: finalUserGroupId
                                        });
                                    }
                                    this.formRecordService.createRecord({
                                        formId: formId,
                                        properties: properties,
                                        stage: this.currentFormStage,
                                        documents: this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                                        isComplete: !isDraft
                                    })
                                        .subscribe(function (data) {
                                        //Done creating the form and appending its properties
                                        resolve(null);
                                        _this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'ram-dashboard' });
                                    }, function (err) {
                                        _this.errorHandler.handleHttpError(err);
                                        reject("Error creating new record");
                                    });
                                    return [2 /*return*/];
                                });
                            }); }, function (err) {
                                _this.errorHandler.handleHttpError(err);
                                reject("Error Creating or updating form");
                            });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    RiskAssessmentFormStage0Component.prototype.ngOnInit = function () {
        this.registerFormFields();
        this.loadEnums();
        this.repopulateFormFromData();
    };
    RiskAssessmentFormStage0Component.prototype.repopulateFormFromData = function () {
        var lastSubmission = this.getMostRecentSubmission(this.formData, true, this.currentFormStage, this.sequence);
        // Do not recall the last submission if it is complete but not specifically requested
        if (!lastSubmission || (!this.sequence && lastSubmission.isComplete)) {
            return;
        }
        this.formRecord = lastSubmission;
        this.updateFromRecordParams(this.form, this.formRecord);
        // get attached documents
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
    };
    RiskAssessmentFormStage0Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.categoryService.getCategories())
            .subscribe(function (data) {
            var reportCategory = data.find(function (data) { return !!data.name.match(/^Risk Task/); });
            if (reportCategory)
                _this.formCategoryId = reportCategory.id;
            //else
            //Notify the user an error has occurred, as we can't find the right form category
        }, function (err) {
            console.error('Error getting enums', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    /**
     * @description Checks if the current risk is a resident type risk. May be one or more.
     * @returns {boolean}
     */
    RiskAssessmentFormStage0Component.prototype.isResidentRisk = function () {
        if (!this.form.riskType.value)
            return false;
        if (riskTypeMap[this.form.riskType.value.toString()].match(/^Resident/)) {
            return true;
        }
        return false;
    };
    /**
     * @description Checks if the current risk is a clinical type risk. May be one or more, and account for "non-clicnical"
     * @returns {boolean}
     */
    RiskAssessmentFormStage0Component.prototype.isClinicalRisk = function () {
        if (!this.form.riskType.value)
            return false;
        if (riskTypeMap[this.form.riskType.value.toString()].match(/\sClinical Risk$/)) {
            return true;
        }
        return false;
    };
    return RiskAssessmentFormStage0Component;
}(FormComponent));
export { RiskAssessmentFormStage0Component };
