<div class="row" *ngIf="!hideHeader">
    <div class="col-12 col-lg-6">
        <img src="assets/images/logo.png" class="logoImg">
    </div>
    <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
        <div class="w-100 border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
            <div class="row">
                <div class="col-12">
                    <strong class="font-weight-heavy">Issue Number:</strong>
                    {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 text-center col-lg-7 col-xl-6 col-xxl-5 text-lg-left">
        <h1 class="text-uppercase text-orange" *ngIf="!hideHeader">Feedback Form</h1>
        <h1 class="text-uppercase text-orange" *ngIf="hideHeader">Feedback Form Record</h1>
    </div>
    <div class="col-12 col-lg-5 col-xl-6 col-xxl-7">
        <hr class="border border-grey border-5 line bw-4 my-4" />
    </div>
</div>
<form>
    <div class="row">
        <div class="col-12 offset-0 col-sm-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label for="originator">Originator Name:</label>
                <input
                    type="text"
                    id="originator"
                    class="form-control {{!readOnly && form.originatorName.iifIsValid('border-success','border-danger')}}"
                    [(ngModel)]="form.originatorName.value"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="readOnly"
                >
                <small class="form-text text-danger" *ngIf="form.originatorName.showErrorHelp()">Invalid Originator</small>
            </div>
        </div>
        <div class="col-12 offset-0 col-sm-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label>Location:</label>
                <location-select
                    [field]="form.location"
                    [disabled]="readOnly"
                    placeholder="Select Location"
                ></location-select>
                <small class="form-text text-danger" *ngIf="form.location.showErrorHelp()">Invalid Location</small>
            </div>
        </div>
        <div class="col-12 offset-0 col-md-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label>Type:</label>
                <enum-select
                    [field]="form.type"
                    placeholder="Select Type"
                    enum="ReportFormType"
                    [disabled]="readOnly"
                ></enum-select>
                <small class="form-text text-danger" *ngIf="form.type.showErrorHelp()">Invalid Type</small>
            </div>
        </div>
        <div class="col-12 offset-0 col-md-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label>Department:</label>
                <user-group-select
                    [field]="form.department"
                    [disabled]="readOnly"
                ></user-group-select>
                <small class="form-text text-danger" *ngIf="form.department.showErrorHelp()">Invalid Department</small>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-8 border-lg-right border-dashed">
            <div class="row">
                <div class="col-12">
                    <span>Originator Type:</span>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="border-lg p-0 p-lg-3">
                        <div class="row">
                            <div class="col-12 offset-0 col-lg-6 col-xl-4">
                                <div class="form-group mb-0">
                                    <enum-select
                                        [field]="form.originatorType"
                                        placeholder="Originator Type"
                                        enum="OriginatorType"
                                        (change)="originatorChanged($event);"
                                        [disabled]="readOnly"
                                    ></enum-select>

                                    <small class="form-text text-danger" *ngIf="form.originatorType.showErrorHelp()">Invalid Originator Type</small>
                                </div>
                            </div>
                            <div class="col-12 pt-2 pt-lg-0 offset-0 col-lg-6 col-xl-4" *ngIf="originatorOptions.residentRoomNumber">
                                <div class="form-group mb-0">
                                    <input
                                        type="text"
                                        class="form-control {{!readOnly && form.residentRoomNumber.iifIsValid('border-success','border-danger')}}"
                                        [placeholder]="form.residentRoomNumber.placeholder"
                                        [(ngModel)]="form.residentRoomNumber.value"
                                        [ngModelOptions]="{standalone: true}"
                                        [disabled]="readOnly"
                                    >
                                    <small class="form-text text-danger" *ngIf="form.residentRoomNumber.showErrorHelp()">Invalid Room Number</small>
                                </div>
                            </div>
                            <div class="col-12 pt-2 pt-xl-0 col-xl-4" *ngIf="originatorOptions.residentEmail">
                                <div class="form-group mb-0">
                                    <input
                                            type="text"
                                            class="form-control {{!readOnly && form.residentEmail.iifIsValid('border-success','border-danger')}}"
                                            placeholder="Resident Email Address"
                                            [(ngModel)]="form.residentEmail.value"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="readOnly"
                                    >
                                    <small class="form-text text-danger" *ngIf="form.residentEmail.showErrorHelp()">Invalid Email Address</small>
                                </div>
                            </div>
                            <div class="col-12 pt-2 pt-xl-0 col-xl-4" *ngIf="originatorOptions.residentName">
                                <div class="form-group mb-0">
                                    <input
                                            type="text"
                                            class="form-control {{!readOnly && form.residentName.iifIsValid('border-success','border-danger')}}"
                                            placeholder="Resident Name"
                                            [(ngModel)]="form.residentName.value"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="readOnly"
                                    >
                                    <small class="form-text text-danger" *ngIf="form.residentName.showErrorHelp()">Invalid Name</small>
                                </div>
                            </div>
                            <div class="col-12 pt-2 pt-xl-0 {{originatorOptions.residentName?'py-xl-2 col-xl-12':'col-xl-8'}}" *ngIf="originatorOptions.originatorEmail">
                                <div class="form-group mb-0">
                                    <input type="text"
                                           class="form-control {{form.originatorEmail.iifIsValid('border-success','border-danger')}}"
                                           placeholder="Originator Email Address"
                                           [disabled]="readOnly"
                                           [(ngModel)]="form.originatorEmail.value"
                                           [ngModelOptions]="{standalone: true}">
                                    <small class="form-text text-danger" *ngIf="!readOnly && form.originatorEmail.showErrorHelp()">Invalid Email Address</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <span>Please Provide Brief Summary:</span>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <textarea
                        class="form-control {{!readOnly && form.summary.iifIsValid('border-success','border-danger')}}"
                        placeholder="Summary"
                        autosize
                        replaceAmpersand
                        [minRows]="3"
                        [spellcheck]="true"
                        [(ngModel)]="form.summary.value"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="readOnly"
                    ></textarea>
                    <small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
                </div>
            </div>
        </div>
        <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <document-uploads [(documents)]="documents" [readOnly]="readOnly" [defaultTickedState]="true"></document-uploads>
                </div>
            </div>
        </div>
    </div>
    <submission-detail [formRecord]="formRecord"></submission-detail>
    <div class="row" *ngIf="!readOnly">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span class="capitalize">Submit</span></button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2">
            <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span class="capitalize">Save Draft</span></a>
        </div>
    </div>
</form>
