import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from "@angular/core";
import * as moment from 'moment';
import "moment-timezone";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { IFormRecordOutputModel, IFormRecordPropertyParam } from "../../../../../../common/contracts/form";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { IFormOutputModel } from "../../../../../../common/contracts/form";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { DocumentIntanceService } from "service/admin/DocumentInstanceService";
import { DocumentService } from "service/DocumentService";
import { saveAs } from "file-saver"
import { DocumentsService } from "service/admin/DocumentsService";
import { environment } from "../../../../../environments/environment";
import { ModalService } from "service/ModalService";
import { Router } from "@angular/router";

@Component({
  selector: 'document-review-form-1',
  templateUrl: './documentReviewFormStage1.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DocumentReviewFormStage1Component extends FormComponent implements OnInit {

  // Existing Form Data
  @Input() public formData: IFormOutputModel;
  @Input() sequence: number;

  public formRecord: IFormRecordOutputModel;
  public documentId: number

  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  @ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

  /*
    This should have been done properly using something that implements FormControl but its
    too late now
   */
  public form: { [key: string]: FormField<any> } = {
    reassign: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    //Since this will be assigned to a select it must be a string data - Conversion where appropriate
    reassignToUserId: new FormField<string>('', {
      validation: FormField.ValidationMethods.None
    }),
    followUps: new FormField<string>('[]', {
      nullEquivilent: "[]",
      validation: (value: string) => {
        return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
      }
    }),
    note: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
    isDocumentStillRequired: new FormField<boolean>(true, {
      validation: FormField.ValidationMethods.IsNotNull
    })
  };

  public dateString: string;

  latestAprovedVersion: any;
  latestDraftRecord: any;
  uploadedDocument: Array<IDocumentType> = [];
  hasDraftFromCompletedTasks: boolean = false;

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formRecordService: FormRecordService,
    public formService: FormService,
    public groupsService: GroupsService,
    private errorHandler: ErrorHandlerService,
    public documentInstanceService: DocumentIntanceService,
    public documentService: DocumentService,
    public documentDRMService: DocumentsService,
    private modalService: ModalService,
    public router: Router
  ) {
    super(router);
  }

  registerFormFields() {
    this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
  }

  ngOnInit() {
    this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
    this.registerFormFields();
    this.repopulateFormFromData();
    this.getLatestAprovedDocument();
    this.getLatestDraft();
    this.setDefaultDocument();
  }

  private setDefaultDocument() {
    if (this.uploadedDocument.length === 0) {
      this.documentDRMService.getDefaultDraftDocument(this.documentId, this.formData ? this.formData.id : null).subscribe(data => {
        if (data) {
          this.uploadedDocument.push(data);
        }
      });
    }
  }

  private getLastSubmission(): IFormRecordOutputModel | null {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return null;

    let submissions = this.formData.records.filter(record => record.isComplete);

    if (!submissions || !submissions.length)
      return null;

    return submissions[submissions.length - 1];
  }

  onSubmit(isDraft: boolean) {
    if (this.uploadedDocument.length === 0) {
      this.modalService.alert({
        title: 'Alert',
        message: 'Document has no approved versions, please upload a draft to continue.'
      });
      return;
    } else {
      this.onFormSubmit(isDraft);
    }
  }

  onFormSubmit(isDraft: boolean) {
    this.session.lockInput(() => {
      return new Promise<void>(async (resolve, reject) => {

        let success = () => {
          resolve();
          
          this.router.navigate(['/drm-dashboard']);
        };

        let fail = (msg: string, err: any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };

        let stage: number;
        let assignedUserId: number | null = null;
        let userGroupId = this.formData.userGroupId;

        if (this.currentUserService.userData)
          assignedUserId = this.currentUserService.userData.id;

        if (!isDraft) {
          if (this.form.reassign.value && this.form.reassignToUserId.value) {
            stage = 1;

            assignedUserId = Number(this.form.reassignToUserId.value);
          } else {
            let lastSubmission = this.getLastSubmission();

            /*
              If:
               There is a last submission
               AND that last submission is a stage 1 submission
               AND that last submission was made by another user
               AND that last submission assigned the form to the current user
            */
            let assignBack: boolean = false;

            if (lastSubmission && lastSubmission.stage === 1
              && lastSubmission.createdById !== assignedUserId
            ) {
              // Prevent the searching of this reassignment property until we're sure it needs to be checked
              let reassignProperty = lastSubmission.properties.find(property => property.property.name === 'reassignToUserId');

              if (reassignProperty && reassignProperty.intData === assignedUserId)
                assignBack = true;
            }

            if (lastSubmission && assignBack) {
              //Assign the submission back to that user, leaving the form in stage 1
              stage = 1;

              assignedUserId = lastSubmission.createdById;
            } else {
              stage = 2;

              // Assign it to senior management
              assignedUserId = null;

              let groups = await this.groupsService.getGroups().toPromise();
              let adminGroup = groups.find(group => group.groupName.match(/^senior management/gi) !== null);

              if (adminGroup)
                userGroupId = adminGroup.id;
              else
                userGroupId = null;
            }
          }
        } else
          stage = 1;

        let properties: Partial<IFormRecordPropertyParam>[] = [];

        this.formService.updateForm({
          id: this.formData.id,
          stage,
          userGroupId,
          assignedUserId
        })
          .subscribe(() => {
            properties.push({
              name: "reassign",
              intData: this.form.reassign.value ? 1 : 0
            });

            if (this.form.reassign.value) {
              // TODO: This fields validation should change from int > 0 to NONE when reassign is true/false
              properties.push({
                name: "reassignToUserId",
                intData: this.form.reassign.value ? Number(this.form.reassignToUserId.value) : null,
              });
            }

            properties.push({
              name: "followUps",
              jsonData: this.form.followUps.value
            });

            properties.push({
              name: "isDocumentStillRequired",
              intData: this.form.isDocumentStillRequired.value ? 1 : 0
            });

            if (this.form.note.value.length > 0)
              properties.push({
                name: "note",
                stringData: this.form.note.value
              });

            this.formRecordService.createRecord({
              formId: this.formData.id,
              // Intentionally cast the properties object since we know its correct
              properties: properties as any,
              stage: 1,
              documents: this.uploadedDocument.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })),
              isComplete: isDraft ? false : true
            })
              .subscribe(data => {
                if (!isDraft) {
                  let params = {
                    userGroupId: userGroupId,
                    stage: 2,
                    createdUserId: this.formData.createdById,
                    documentId: this.documentId,
                    completedBy: this.currentUserService.userData ? this.currentUserService.userData.id : null
                  }
                  this.formService.sendReviewFormNotifications(params).subscribe();
                }
                success();
              },
                err => fail('Error creating a record', err)
              );
          }, err => fail('Error updating a form', err)
          );
      });
    });
  }

  private repopulateFormFromData() {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return;

    let stageRecords = this.formData.records.filter(record => record.stage === 1);
    if (stageRecords.length === 0)
      return;

    if (!this.sequence) {
      let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

      if (!mostRecentRecord)
        throw new Error("internal error");

      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find(record => record.sequence === this.sequence);

      if (!targetRecord)
        throw new Error("internal error");

      this.formRecord = targetRecord;
    }

    //Convert the properties into easily accessible IFormRecordPropertyParam
    if (!this.formRecord.properties)
      return;

    this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);

    let simpleProperties: { [key: string]: IFormRecordPropertyParam } = {};

    this.formRecord.properties.forEach(recordProperty => {
      //eject invalid property
      if (!recordProperty.property)
        return;

      let result: Partial<IFormRecordPropertyParam> = {
        name: recordProperty.property.name
      };

      if (recordProperty.stringData)
        result.stringData = recordProperty.stringData;

      if (recordProperty.intData)
        result.intData = recordProperty.intData;

      if (recordProperty.jsonData)
        result.jsonData = recordProperty.jsonData;

      if (recordProperty.enumId)
        result.enumId = recordProperty.enumId;

      simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
    });

    stageRecords = this.formData.records.filter(record => record.stage === 1);

    let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

    if (!mostRecentRecord)
      return;

    let followUpRecord =
      mostRecentRecord.properties.find(recordProperty => recordProperty.property.name === 'followUps');

    if (followUpRecord) {
      this.form.followUps.value = followUpRecord.jsonData;
    }

    /*
      If the previous record was not from the current user,
      or if a sequence was specified, we only want the followUps
    */

    if (!this.formRecord.isComplete || this.sequence) {
      if (simpleProperties['reassignToUserId'])
        this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);

      if (simpleProperties['reassign']
        && simpleProperties['reassign'].intData !== null
        && simpleProperties['reassign'].intData !== undefined) {
        this.form.reassign.value = (simpleProperties['reassign'].intData > 0);
        this.showReassignOptions(this.form.reassign.value);
      }

      this.uploadedDocument = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

      if (simpleProperties['note'])
        this.form.note.value = simpleProperties['note'].stringData;
    }

    if (simpleProperties['isDocumentStillRequired'])
      this.form.isDocumentStillRequired.value = simpleProperties['isDocumentStillRequired'].intData ? true : false;

    if (simpleProperties['followUps'])
      this.form.followUps.value = simpleProperties['followUps'].jsonData;
  }

  public showReassignOptions(state: boolean): void {
    this.form.reassign.value = state;
    if (state) {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
      this.setFieldValidation(this.form.summary, FormField.ValidationMethods.None);
    } else {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
      this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
    }
  }

  public getLatestDraft() {
    this.formService.getFormsFromDocumentId(this.documentId).subscribe(data => {
      if (data.length === 0) {
        this.documentInstanceService.getDraftDocument(this.documentId).subscribe(data => {
          if (data) {
            this.latestDraftRecord = data;
            this.hasDraftFromCompletedTasks = true;
          } else {
            this.latestDraftRecord = null;
          }
        });
      } else {
        const formsCreatedAtArray = data.map(form => new Date(form.createdAt)),
          latestFormCreatedAt = new Date(Math.max.apply(null, formsCreatedAtArray)),
          latestFormRecords = data.find(form => (new Date(form.createdAt).getTime() === latestFormCreatedAt.getTime())).records,
          latestRecord = latestFormRecords.find(record => new Date(record.createdAt).getTime() === new Date(Math.max.apply(null, latestFormRecords.filter(record => !!record.documents.length).map(record => new Date(record.createdAt)))).getTime())

        this.latestDraftRecord = latestRecord;
      }
    });
  }

  public downloadLatestDraft() {
    if (this.latestDraftRecord === null) {
      this.downloadLatestAprovedVersion();
    } else if (this.hasDraftFromCompletedTasks && this.latestDraftRecord) {
      this.documentInstanceService.downloadDraftDocument(this.latestDraftRecord.id).subscribe(data => {
        saveAs(data, this.latestDraftRecord.originalFileName);
      })
    } else {
      this.documentService.downloadDocument(this.latestDraftRecord.documents[0].id).subscribe(file => {
        saveAs(file, this.latestDraftRecord.documents[0].fileName)
      });
    }
  }

  private getLatestAprovedDocument() {
    if (this.formData) {
      const formPropertiesArray = this.formData.records.map(record => record.properties),
        flatenedFormPropertiesArray = [].concat.apply([], formPropertiesArray),
        propertyWithDocumentId = flatenedFormPropertiesArray.find(property => property.property.name === "documentId");
      this.documentId = propertyWithDocumentId.stringData;

    }

    this.documentDRMService.getDocumentInstanceByDocumentId(this.documentId).subscribe(file => {
      this.latestAprovedVersion = file;
    });
  }

  public downloadLatestAprovedVersion() {
    this.documentInstanceService.downloadDocument(this.latestAprovedVersion.id).subscribe(data => {
      saveAs(data, this.latestAprovedVersion.fileName);
    });
  }
}
