<div class="row">
    <div class="col-12 col-lg-6">
        <img src="assets/images/logo.png" class="logoImg">
    </div>
    <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
        <div class="border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
            <div class="row">
                <div class="col-12">
                    <strong class="font-weight-heavy">Issue Number:</strong>
                    {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="d-flex">
            <div class="pr-2 text-center text-lg-left">
                <h1 class="text-uppercase text-orange">FOLLOW UP RECORD (FUR)</h1>
            </div>
            <div class="flex-grow-1">
                <hr class="border border-grey border-5 line bw-4 my-4" />
            </div>
        </div>
    </div>
</div>
<form>
    <div class="row">
        <div class="col-12">
            <span class="h6">1. This "FUR" follows-up...</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="border">
                <div class="row">
                    <div class="col-12">
                        <buttons-switch
                            [disabled]="readOnly"
                            [options]="followUpTypes"
                            [(value)]="form.followUpType.value">
                        </buttons-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12 offset-0 col-sm-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label>Location:</label>
                <location-select
                    [field]="form.location"
                    [disabled]="readOnly"
                ></location-select>
                <small class="form-text text-danger" *ngIf="form.location.showErrorHelp()">Invalid Location</small>
            </div>
        </div>
        <div class="col-12 offset-0 col-sm-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label>Form Title:</label>
                <enum-select
                        [field]="form.type"
                        placeholder="Select Type"
                        enum="ReportFormType"
                        [disabled]="readOnly"
                ></enum-select>
                <small class="form-text text-danger" *ngIf="form.type.showErrorHelp()">Invalid Type</small>
            </div>
        </div>
        <div class="col-12 offset-0 col-md-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label>Due Date</label>
                <date-picker [disabled]="readOnly" [formField]="form.dueAt" [disablePastDates]="true"></date-picker>
            </div>
        </div>
        <div class="col-12 offset-0 col-md-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-0">
            <div class="form-group">
                <label>Department:</label>
                <user-group-select
                    [field]="form.department"
                    [disabled]="readOnly"
                ></user-group-select>
                <small class="form-text text-danger" *ngIf="form.department.showErrorHelp()">Invalid Department</small>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-8 border-lg-right border-dashed">
            <div class="row my-3">
                <div class="col-12">
                    <span>FUR Detail:</span>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <textarea
                        class="form-control {{!readOnly && form.summary.iifIsValid('border-success','border-danger')}}"
                        replaceAmpersand
                        autosize
                        [minRows]="3"
                        [placeholder]="readOnly ? '' : 'Summary'"
                        [spellcheck]="true"
                        [(ngModel)]="form.summary.value"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="readOnly"
                    ></textarea>
                    <small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
                </div>
            </div>
        </div>
        <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <document-uploads [(documents)]="documents" [readOnly]="readOnly" [disableTicking]="true"></document-uploads>
                </div>
            </div>
        </div>
    </div>
    <submission-detail [formRecord]="formRecord"></submission-detail>
    <div class="row" *ngIf="!readOnly">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span class="capitalize">Submit</span></button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2">
            <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span class="capitalize">Save Draft</span></a>
        </div>
    </div>
</form>

