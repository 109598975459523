import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from "@angular/core";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { IFormOutputModel } from "../../../../../../common/contracts/form";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { Router } from "@angular/router";
import { riskTypeMap } from "../../../../../model/RiskType";
import { UsersService } from "service/admin/UsersService";
import { logger } from "service/util/Logger";
import { Risk } from "../../../../../../common/Risk";
import { ModalService } from "service/ModalService";
import { IEnumsOutputModel } from "../../../../../../common/contracts/enums";
import { EnumService } from "service/EnumService";
import { FollowUpService } from "service/FollowUpService";

@Component({
	selector: 'risk-assessment-form-1',
	templateUrl: './riskAssessmentFormStage1.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class RiskAssessmentFormStage1Component extends FormComponent implements OnInit {

	// Existing Form Data
	@Input() public formData: IFormOutputModel;
	@Input() sequence: number;

	public formRecord: IFormRecordOutputModel;
	public currentFormStage = 1;
	public className = "RiskAssessmentFormStage1Component";
	public followUpFormTypeId: number | null = null;

	@Input() readOnly: boolean = false;
	@Input() hideHeader: boolean = false;

	@ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

	/*
		This should have been done properly using something that implements FormControl but its
		too late now
	 */
	public form = {
		riskRemoved: new FormField<boolean>(false, {
			validation: FormField.ValidationMethods.None,
			onChange: () => { this.updateFormValidation(); },
			recordParamType: RecordParamType.Boolean
		}),
		riskLevelChanged: new FormField<boolean>(false, {
			isVisible: false,
			validation: FormField.ValidationMethods.None,
			onChange: () => { this.updateFormValidation(); },
			recordParamType: RecordParamType.Boolean
		}),
		reassign: new FormField<boolean>(false, {
			isVisible: false,
			validation: FormField.ValidationMethods.None,
			onChange: () => { this.updateFormValidation(); },
			recordParamType: RecordParamType.Boolean
		}),

		//Since this will be assigned to a select it must be a string data - Conversion where appropriate
		reassignToUserId: new FormField<string>('', {
			validation: FormField.ValidationMethods.None
		}),

		/* Initial Severity Calculations */
		severity: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number,
			onChange: () => {
				this.calculateRiskLevel(this.form.occuranceLikelyhood, this.form.severity, this.form.riskLevel);
			}
		}),

		occuranceLikelyhood: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number,
			onChange: () => {
				this.calculateRiskLevel(this.form.occuranceLikelyhood, this.form.severity, this.form.riskLevel);
			}
		}),

		riskLevel: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number
		}),

		mitigationMeasures: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),

		postMitigationSeverity: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number,
			onChange: () => {
				this.calculateRiskLevel(this.form.postMitigationOccuranceLikelyhood, this.form.postMitigationSeverity, this.form.postMitigationRiskLevel);
			}
		}),
		postMitigationOccuranceLikelyhood: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number,
			onChange: () => {
				this.calculateRiskLevel(this.form.postMitigationOccuranceLikelyhood, this.form.postMitigationSeverity, this.form.postMitigationRiskLevel);
			}
		}),
		postMitigationRiskLevel: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number
		}),

		recommendations: new FormField<string>('', {
			validation: FormField.ValidationMethods.None
		}),

		howResidentHasBeenInformed: new FormField<string>('', {
			isVisible: false,
			validation: FormField.ValidationMethods.None
		}),

		followUps: new FormField<string>('[]', {
			nullEquivilent: "[]",
			recordParamType: RecordParamType.JSON,
			validation: (value: string) => {
				return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
			}
		}),

		summary: new FormField<string>('', {
			isVisible: false,
			validation: FormField.ValidationMethods.None
		}),
	};

	public documents: Array<IDocumentType> = [];
	public riskType: string = 'General Risk';
	public isExistingRisk: boolean = false;
	public isResidentRisk: boolean = false;

	// When true this form will assign to the department head for signoff instead
	private departmentUserGroupId: number | null = null;

	public likelihoodSelectOptions = [{ id: '', text: '' }].concat(Risk.LikelihoodOptions);
	public severitySelectOptions = [{ id: '', text: '' }].concat(Risk.SeverityOptions);
	public riskSelectOptions = [{ id: '', text: '' }].concat(Risk.RiskOptions);

	constructor(
		public session: Session,
		public currentUserService: CurrentUserService,
		public formRecordService: FormRecordService,
		public formService: FormService,
		public groupsService: GroupsService,
		private errorHandler: ErrorHandlerService,
		public router: Router,
		public usersService: UsersService,
		public modalService: ModalService,
		public enumService: EnumService,
		public followUpService: FollowUpService
	) {
		super(router);
	}

	/**
	 * @description Syncs form validation against state data
	 */
	updateFormValidation() {
		const signature = this.className + '.updateFormValidation: ';
		this.resetAllValidation();
		this.form.reassign.isVisible = this.currentUserService.isAdministratorOrManager.getValue();

		logger.silly(signature + `Updating Form Validation`);

		if (this.form.reassign.value) {
			this.setFieldValidation(this.form.severity, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.occuranceLikelyhood, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.riskLevel, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.mitigationMeasures, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.postMitigationSeverity, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.postMitigationOccuranceLikelyhood, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.postMitigationRiskLevel, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.recommendations, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.howResidentHasBeenInformed, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);

			this.form.mitigationMeasures.isVisible = false;
			this.form.recommendations.isVisible = false;
			this.form.riskRemoved.isVisible = false;
		}

		if (this.isExistingRisk) {
			this.setFieldValidation(this.form.severity, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.occuranceLikelyhood, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.riskLevel, FormField.ValidationMethods.None);

			this.form.riskLevelChanged.isVisible = true;
		}

		if (this.isResidentRisk) {
			this.setFieldValidation(this.form.howResidentHasBeenInformed, FormField.ValidationMethods.IsNotBlank);
			this.form.howResidentHasBeenInformed.isVisible= true;
		}

		if (!this.form.riskLevelChanged.value) {
			this.setFieldValidation(this.form.severity, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.occuranceLikelyhood, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.mitigationMeasures, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.recommendations, FormField.ValidationMethods.None);

			this.form.severity.isVisible = false;
			this.form.occuranceLikelyhood.isVisible = false;
			this.form.mitigationMeasures.isVisible = false;
			this.form.recommendations.isVisible = false;
			this.form.riskRemoved.isVisible = false;

			this.form.riskRemoved.patch(false);
		}

		if (!this.form.riskLevelChanged.value || this.form.riskRemoved.value) {
			this.setFieldValidation(this.form.postMitigationSeverity, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.postMitigationOccuranceLikelyhood, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.postMitigationRiskLevel, FormField.ValidationMethods.None);

			this.form.postMitigationSeverity.isVisible = false;
			this.form.postMitigationOccuranceLikelyhood.isVisible = false;
			this.form.postMitigationRiskLevel.isVisible = false;
		}

		if (this.form.riskRemoved.value || this.form.reassign.value) {
			this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
		}

		if (!this.form.riskLevelChanged.value || this.form.riskRemoved.value || this.form.reassign.value) {
			this.form.summary.isVisible = true;
		}

		if( this.form.reassign.value ) {
			this.form.riskLevelChanged.isVisible = false;
		}
	}

	async ngOnInit() {
		this.loadEnums();
		this.registerFormFields(this.form);

		await this.repopulateFormFromData();
		this.updateFormValidation();
	}

	/**
	 * @description Calculates risk level and updates the risk level form item
	 * @param {FormField<string>} likelihood 
	 * @param {FormField<string>} severity
	 * @param {FormField<string>} riskOutput Destination field to set the new risk elvel
	 */
	calculateRiskLevel(likelihood: FormField<string>, severity: FormField<string>, riskOutput: FormField<string>) {
		const calculatedRisk = Risk.calculateRisk(likelihood.value, severity.value);
		riskOutput.value = calculatedRisk ? calculatedRisk.id.toString() : null;
	}

	onSubmit(isDraft: boolean = false) {
		const signature = this.className + ".onSubmit:";
		this.session.lockInput(() => {
			return new Promise(async (resolve, reject) => {
				let assignedUserId: number | null = this.currentUserService.userData && isDraft ? this.currentUserService.userData.id : null;

				/**
				 * Destination Stages
				 * Stage 1: Draft
				 * Stage 2: Relevant Department Review
				 * Stage 4: Complete
				 */

				let stage: number = 1;
				let userGroupId: number | null = this.formData.userGroupId;

				if (!isDraft) {
					if (this.form.reassign.value && Number(this.form.reassignToUserId.value)) {
						// The user is intending to reassign this form to another user
						stage = 1;
						userGroupId = this.formData.userGroupId;
						assignedUserId = Number(this.form.reassignToUserId.value);
						logger.silly(signature + `Assigning form to User[${assignedUserId}] and Group[${userGroupId}]`);
					} else if(this.isExistingRisk && !this.form.riskRemoved.value && !this.form.riskLevelChanged) {
						// Existing risk, not removed, unchanged risk level
						stage = 4;
						userGroupId = this.departmentUserGroupId;
					} else {
						// New risks, or existing risks that were removed/changed will go to Clinical->CGA->Senior mgmt, non-clinical->Senior Mgmt
						const isClinicalRisk = !!this.riskType.toLowerCase().replace(/[^a-z]/g,'').match(/clinical/);

						stage = isClinicalRisk ? 2 : 3;
						userGroupId = await this.groupsService.getGroupIdByName(isClinicalRisk ? "Clinical Governance" : "Senior Management"); 
					}
				} else {
					logger.silly(signature + `Draft will be assigned to User[${assignedUserId}] and Group[${userGroupId}]`);
				}

				this.formService.updateForm({
					id: this.formData.id,
					formLocationId: this.formData.formLocationId,
					userGroupId,
					notifyOnComplete: this.formData.notifyOnComplete,
					stage,
					assignedUserId
				}).subscribe((data: any) => {
					const properties = this.toRecordParams(this.form);

					this.formRecordService.createRecord({
						formId: this.formData.id,
						properties: properties as any,
						stage: this.currentFormStage,
						documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })), // send list of attached documents
						isComplete: !isDraft
					})
						.subscribe((data: any) => {
							const success = () => {
								resolve(data);

								this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'ram-dashboard' });
							}

							if (stage === 4) {
								this.formService.finalizeForm(this.formData.id).subscribe(() => {
									let followUps: { userGroupId: string, description: string, dueDate: string }[] = [];

									if (this.form.followUps.value && this.form.followUps.value.length) {
										followUps = JSON.parse(this.form.followUps.value);
									}

									if (followUps.length === 0)
										return success();

									this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, this.followUpFormTypeId, success);
								});
							} else {
								success();
							}
						}, err => {
							this.errorHandler.handleHttpError(err);
							reject("Error creating new record");
						});
				}, (err) => {
					this.errorHandler.handleHttpError(err);
					reject("Error updating form");
				});
			});
		});
	}

	/**
	 * @description Fetches the original data from stage0
	 */
	private handleInitialData(): void {
		const lastStage0Submission = this.getMostRecentSubmission(this.formData, false, 0);

		if (lastStage0Submission) {

			const propsOfInterest = {
				riskType: new FormField<string>('1', {
					recordParamType: RecordParamType.Number
				}),
				isExistingRisk: new FormField<boolean>(false, {
					recordParamType: RecordParamType.Boolean
				}),
				existingRisk: new FormField<number>(null, {
					recordParamType: RecordParamType.Number
				}),
				existingRiskLevel: new FormField<string>(null, {}),
			};

			this.updateFromRecordParams(propsOfInterest, lastStage0Submission);

			this.riskType = riskTypeMap[(propsOfInterest.riskType.value || '1').toString()];
			this.isExistingRisk = !!(propsOfInterest.isExistingRisk.value && propsOfInterest.existingRisk.value);
			this.form.riskLevelChanged.patch(!this.isExistingRisk);

			if (this.isExistingRisk) {
				const existingRiskLevel = Risk.getEnumByKey(propsOfInterest.existingRiskLevel.value, Risk.Risk);
				this.form.riskLevel.value = (existingRiskLevel || Risk.Risk.Low).id.toString();
			}

			if (this.riskType.toString().match(/^Resident/)) {
				this.isResidentRisk = true;
			}

			this.departmentUserGroupId = this.getIntData(lastStage0Submission, 'initialAssessorDepartmentId');

			this.updateFormValidation();
		} else {
			throw new Error("There should always be a stage 0 submission");
		}
	}

	private async repopulateFormFromData() {
		this.handleInitialData();

		if (this.sequence) {
			const sequenceSubmission = this.getMostRecentSubmission(this.formData, false, this.currentFormStage, this.sequence);

			return this.reloadExistingData(sequenceSubmission);
		}

		const lastSubmission = this.getMostRecentSubmission(this.formData, true, this.currentFormStage);
		if (lastSubmission && !lastSubmission.isComplete) {
			// We have draft Data
			return this.reloadExistingData(lastSubmission);
		}

		const mostRecentNonAssignment = this.getMostRecentSubmission(this.formData, false, this.currentFormStage, undefined, record => {
			return !this.getStringData(record, 'reassignToUserId')
		});

		if (mostRecentNonAssignment) {
			this.reloadExistingData(mostRecentNonAssignment);
		}

		return this.loadFollowUpData();
	}

	/**
	 * @description Loads follow up data from the most recent submission of any type
	 */
	private loadFollowUpData() {
		if (!this.form.followUps.valueIsNull) return;

		const mostRecentSubmission = this.getMostRecentSubmission(this.formData, true);

		if (mostRecentSubmission) {
			const followUpData = this.getJsonData(mostRecentSubmission, 'followUps');
			this.form.followUps.value = followUpData;
		}
	}

	/**
	 * @description Handles an incoming submission record by loading it into the form
	 * @param {IFormRecordOutputModel|null} submission Allows null for convenience, does nothing if null is supplied
	 * @returns {void}
	 */
	private reloadExistingData(submission: IFormRecordOutputModel | null): void {
		const signature = this.className + ".reloadExistingData: ";
		if (!submission) return;

		this.formRecord = submission;
		this.updateFromRecordParams(this.form, this.formRecord);
		this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
		logger.silly(signature + `Reloaded existing data from Submission[${submission.id}] with Sequence[${submission.sequence}] in Stage[${submission.stage}]`)
	}

	/**
	 * @description Handles click events to show the risk matrix
	 */
	public showRiskMatrix(): void {
		this.modalService.risk();
	}

	private loadEnums() {
		this.session.lockInputRx(this.enumService.getEnumsByName('reportFormType'))
			.subscribe((data: IEnumsOutputModel[]) => {
				const followUpFormType = data.find(typeEnum => typeEnum.value.toLowerCase() === 'other');
				if (followUpFormType) {
					this.followUpFormTypeId = followUpFormType.id;
				}
			});
	}

	public updateRiskLevelChanged(riskLevelChanged: boolean) {
		this.form.riskLevelChanged.value = riskLevelChanged;
	}

	public updateRemoveState(riskRemoved: boolean) {
		this.form.riskRemoved.value = riskRemoved;
	}
}