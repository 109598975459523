import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {GenericService} from "./GenericService";
import { IFormsQueryParams } from "../../common/contracts/form";
import { stringifyObjectValues } from "./util/stringifyObjectValues";
import { ISortOrderValue } from '../../common/contracts/common';

@Injectable()
export class ReportService extends GenericService {

  static apiPrefix = '/api/report';

  constructor(private http: HttpClient) {
    super();
  }

  public downloadReadOnlyFormsReport(formId: number) {
    return this.http.get(`${ReportService.apiPrefix}/${formId}` , {
      responseType: 'blob',
    });
  }

  public downloadDashboardCsvReport(params: IFormsQueryParams) {
    const queryParams = new HttpParams({
      fromObject: stringifyObjectValues(params)
    });

    return this.http.get(`${ReportService.apiPrefix}/dashboardCsv` , {
      params: queryParams,
      responseType: 'blob',
    });
  }

  public downloadDRMDashboardCsvReport(params: IFormsQueryParams) {
    const queryParams = new HttpParams({
      fromObject: stringifyObjectValues(params)
    });

    return this.http.get(`${ReportService.apiPrefix}/drmDashboardCsv` , {
      params: queryParams,
      responseType: 'blob',
    });
  }

  public downloadRISKcsvReport(offset: number = 0, pageSize: number = 20, sortBy = 'id', order: ISortOrderValue = 'asc', searchFilter: string, locationFilter:string,riskLevelFilter:string, riskStatusFilter:string,riskTypeId:string) {
    const queryParams = new HttpParams({
      fromObject: {
        skip: offset.toString(),
        limit: pageSize.toString(),
        sortBy,
        order,
        searchFilter,
        locationFilter,
        riskLevelFilter,
        riskStatusFilter,
        riskTypeId
      }
    });
  
    return this.http.get(`${ReportService.apiPrefix}/downloadRiskCSV` , {
      params: queryParams,
      responseType: 'blob',
    });
  }
}



